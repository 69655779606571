import { Injectable } from '@angular/core';
import { Restangular } from 'ngx-restangular';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import { environment } from "../../environments/environment";
import { Observable } from "rxjs";
import {tap} from "rxjs/operators";
import { AuthenticationHelper } from 'app/helpers/authentication';

@Injectable()
export class BookingService {
    bookingBaseRoute = 'api/booking';
    getFarmersByMobile: any = 'api/farmersmobile';
    getFarmerUserByMobile: any = 'api/farmer-user';
    getFarmerDetailsByMobile: any = 'api/farmer-user-mdm';
    getFarmerByGuidRoute: any = 'api/farmersguid';
    getAllFarmersRoute: any = 'api/farmers';
    getBookingRequestRoute: any = 'api/booking-requests';
    getSprayingByFarmerRoute: any = 'api/sprayingByFarmer/v1';
    getSprayingUidLocationTrackerRoute: any = 'api/getGeoSpatialCordinates/';
    getAllMisscallBookingsRoute: any = 'api/bookServiceByMisscall';
    updateBookingsAssignmentRoute: any = 'api/assign-farmer-booking-request';
    getAllProductListRoute: any = 'api/products';
    getAllProductDatsRoute: any = 'api/get-product-points';
    getllAllProductsOfMdmRoute: any = 'api/mdm-products';
    getAllSimilarProductsRoute:any = 'api/similar-products';
    saveProductScanPointsRoute: any = 'api/farmer-app-product-scan-details';
    getAllCropsRoute: any = 'api/crops';
    uploadMediaRoute: any = 'api/create-update-media';
    postBookingRequestRoute: any = 'api/bookingRequest';
    getAllBookingSource: any = 'api/booking-source-type';
    getAllFarmerSource: any = 'api/farmer-source-type';
    getAllAssignedBookingsRoute: any = 'api/assigned-bookingrequest-list';
    getUnAllAssignedBookingsRoute: any = 'api/unassigned-and-exceptOperatorApp-bookingRequest-list';
    calculateNDVIRoute: any = 'api/satellite/images/ndvi/farmer/';
    getFarmerLandSoilMoistureRoute: any = 'api/satellite/soilmoisture/farmer';
    getProductByQRCodeRoute: any = 'api/product-by-barcode';
    getFarmerVoiceServiceRoute: any = 'api/farmer-voice-service';
    getAllIncomingBookingsRoute: any = 'api/booking-service-v1';
    getAllBookingAnalyticsRoute: any = 'api/booking-analytics';
    verifySingleBookingRoute: any = 'api/booking-service/verify';
    invalidateBookingRequestRoute: any = 'api/booking-service/invalidate';
    getStateWiseSprayingRequestRoute: any = 'api/state-wise-spraying-count';
    getAllSprayingRequestRoute: any = 'api/spraying-activity';
    updateSprayingRoute: any = 'api/sprayings';
    getProductImageRoute: any = 'api/spraying-product-images';
    getAllActiveBookingStatusByMobileNumberRoute = 'api/booking';
    getAllSeedVarityRoute: any = 'api/seed-varieties';
    getNewCreateBookingRoute: any = 'api/booking';
    getAllListOfBookingsRoute: any = 'api/booking/list';
    getSelectedBookingByBookingIDRoute: any = 'api/booking/get/';
    getFarmerByMobileNumberIfBookingNotAvailableRoute: 'api/booking/farmer-mobile/';
    getFarmerByMobileNumberRoute = 'api/booking/farmer-mobile';
    getOneBooking = 'get';
    reassignBookingToOperatorRoute = 'api/booking/reassign';
    getAllSeasonWiseCropAndSeedRoute = 'api/season-crops';
    getFarmerLandsDetailsRoute = 'api/booking/land-details';
    exportBookingDataToExcelRoute = 'api/booking/export-to-xls';
    getAllActorsListRoute = 'api/booking/actor-types';
    getAllReasonListRoute = 'api/booking/reasons/';
    sendBookingSMSRoute = 'api/booking/send-sms';
    getWalletInfoRoute = 'api/booking/wallet-info';
    getBillingDetailsInfoRoute = 'api/booking/billing-info/';
    getFarmersOTPByMobile = 'api/get-farmer-app-otp/';
    getSoilTestingServiceCharges = '/api/booking/get-charges';
    getServiceTypes = '/api/booking-config/booking-groups';
    uploadReport = 'core/dragonfly/upload';
    getBookingStatus = '/api/booking/get-booking-spec';
    getUploadfile = 'api/soil-testing/upload-report';
    getUploadImage = '/core/dragonfly/download';
    getServiceStatus = 'api/booking/get-all-service-status';
    getSMSDetails = 'api/communication/list';
    getFarmerInsuranceDetails = 'api/get-farmer-active-insurance/';
    getFarmerOnlineTransactionDeatils = 'api/insurance/transactions/actor/';
    getGerminationTransactionDetailsRoute = "api/insurance/transactions/germination/actor/";
    getPA_OnlineTransactionDeatils = 'api/insurance/transactions/pa/actor/';
    getReferalDetais = 'api/get-farmer-app-referral';
    getFarmerBookingLog = 'api/booking/get-booking-history';
    getPicturecategory = '/api/picture-capability/category-type';
    getVerificationStatus = '/api/picture-capability/approval-status-type';
    getAgentdetails = '/api/picture-capability/intern-user';
    getRejectionReason = '/api/picture-capability/approval-status-reason-type';
    getAllListOfPictureRoute: any = '/api/picture-capability/get-pictures-with-filters';
    getAllLandRecordRoute: any = '/api/land-record-verification/get-program-with-filters';
    updatelandRecordRout:any = '/api/land-record-verification/mark-status'
    approvePictureroute = '/api/picture-capability/mark-picture-status';
    uploadFarmerInsuranceFileRoute = 'api/farmer-insurance/upload';
    uploadPainsuarnceRoute = 'api//upload-pa-insurance'
    uploadInsuranceFileRoute = 'api/uploadInsuranceData';
    getVideoCategory = 'api/video-feed/content-category-type';
    getS3UrlForYtUpload = 'api/video-feed/video-upload';
    geMimeTypeRoute = 'api/video-feed/content-type';
    getVideoVerificationStatusList = 'api/video-feed/verification-status-type';
    getLanguageDetails = 'api/video-feed/language-type';
    getImageQaulityDetailsRoute = 'api/video-feed/verification-image-quality';
    getCropQaulityDetailsRoute = 'api/video-feed/verification-crop-quality';
    getCropStageListRoute ='api/video-feed/crop-stages';
    getDamageTypeListRoute = 'api/video-feed/damage-types';
    getDamageNamesListRoute = 'api/video-feed';
    getPlantPartListRoute = 'api/video-feed/plant-parts'
    getAllVideoDetailRoute = 'api/video-feed/get-content-with-filters';
    getProgramTypesRoute = 'api/video-feed//program-type';
    approveVideoRoute = 'api/video-feed/mark-content-status';
    getFarmerScandetail = 'api/farmer-manual-product-scan-details';
    getDnationDetails = 'api/get-donation-details';
    getApproverDetails = 'api/picture-capability/approval-users';
    getAllCampaignListRoute = 'api/campaign/get-campaign-list';
    deleteCampaignRoute = 'api/campaign/delete';
    restartCampaignRoute = 'api/campaign/restart';
    verifyAthenaQueryRoute = 'api/campaign/verify-athena-query';
    verifyBannersAthenaQueryRoute = 'api/banners/verify-athena-query';
    createCampaignRoute = 'api/campaign/create';
    testCampaignRoute = 'api/campaign/test-new';
    getCampaignRoute = 'api/campaign/get-campaign';
    updateCampaignRoute = 'api/campaign/update';
    getTemplateListRoute = 'api/template/get-list';
    testExistingCampaignRoute = 'api/campaign/test-existing';
    uploadDynamicMediaTypeRoute = 'api/campaign/add-dynamic-media';
    getPollsTypeRoute = 'api/user-feed/questionnaire-type';
    getPollsStatusRoute = 'api/user-feed/questionnaire-status';
    getPollsOptionsRoute = 'api/user-feed/no-of-options';
    getAllPollsListRoute = '/api/user-feed/get-questions';
    createPollsListRoute = '/api/user-feed/questions';

    uploadbannerRoute = 'api/banners/upload-media';
    uploadbannerDetailsRoute = 'api/banners';
    getbannerDetailsRoute = 'api/banners';
    getBannerListWithFilter = 'api/banners/with-filters';
    getFarmerOpenOrdersRoute: any = 'api/trade/executeGetCCOrdersByFarmerId';
    getInsurenceTypeList: any = 'api/insurance/type';
    getBookingInvoiceDetailsRoute: any = 'api/booking-invoice-details';
    getLandRecordStatusLst :any = '/api/land-record-verification/approval-status-type';
    geLandRecordProgramType: any = '/api/land-record-verification/programs';
    getRejectLandRecordReasonsRoute: any = '/api/land-record-verification/approval-status-reason-type';
    getGeofencingStatusRoute: any = '/api/picture-capability/geo-fence-type';
    getCampaignQueriesRoute: any = '/api/campaign/get-campaign-queries';
    saveQueryToDBRoute: any = '/api/campaign/add-campaign-query';
    getBulkUploadInsuranceListRoute = '/api/bulk-upload-insurance-type';
    getinsuranceUploadHistoryRoute = '/api/get-insurance-upload-history';
    getPaginatedInsuranceUploadHistoryByIdRoute = '/api/get-insurance-upload-error-details-pagination';
    getInsuranceUploadHistoryByIdRoute = '/api/get-insurance-upload-error-details';
    bannerlanguageRoute: any = 'api/banners/get-languages';

    //D2F API's
    getAllOrdersRoute = '/api/orders/searchOrder';
    getOrderRoute = 'api/orders';
    baseURL: string = environment.baseUrl;
    gaphqlBaseUrl: string = "https://dev.nurture.farm/"
    serviceAreaId: any;
    constructor(private restangular: Restangular, private http: HttpClient) {   

    }

    /**
     * To create custom header by passing NF-Service-Area-Id
     */
    createHeader() {
        return (new HttpHeaders({
            Authorization: `Bearer ${AuthenticationHelper.getToken()}`,
            // 'NF-Service-Area-Id': localStorage.getItem('serviceAreaId')
        }));
    }

    createHeaderVersionV1() {
        return (new HttpHeaders({
            Authorization: `Bearer ${AuthenticationHelper.getToken()}`,
            'NF-Version': 'V1'
        }));
    }

    createHeaderVersionV2() {
        return (new HttpHeaders({
            Authorization: `Bearer ${AuthenticationHelper.getToken()}`,
            'NF-Version': 'V2'
        }));
    }

    createHeaderForTemplates(){
        return (new HttpHeaders({
            Authorization: `Bearer ${AuthenticationHelper.getToken()}`,
            'clientId': environment.CampaighnTemplateClientId,
            'clientSecret': environment.CampaighnTemplateClientSecretId
        }));
    }

    /**
     * api/farmersmobile
     */
    getFarmerByMobile(mobileNumber) {
        return this.restangular.one(this.getFarmersByMobile, mobileNumber);
    }

    /**
     * api/farmersmobile
     */
    FarmerUserByMobile(mobileNumber) {
        return this.restangular.one(this.getFarmerUserByMobile, mobileNumber);
    }

    getFarmerDetailsByMobileNo(mobileNumber) {
        return this.restangular.one(this.getFarmerDetailsByMobile, mobileNumber);
    }

    /**
     * api/farmers
     */
    getAllFarmers() {
        return this.restangular.all(this.getAllFarmersRoute);
    }

    /**
     * api/farmers/{farmerId}
     */
    getSingleFarmers(farmerId?) {
        return this.restangular.one(this.getAllFarmersRoute, farmerId);
    }

    /**
     * api/farmers
     */
    getBookingRequest(mobileNumber, sourceType?) {
        return this.restangular.one(this.getBookingRequestRoute, mobileNumber).one(sourceType);
    }

    /**
     * api/sprayingByFarmer
     */
    getSprayingByFarmerGuid() {
        return this.restangular.one(this.getSprayingByFarmerRoute);
    }

    /**
     * api/farmersguid
     */
    getFarmerByGuid(farmerGuid?) {
        return this.restangular.one(this.getFarmerByGuidRoute, farmerGuid);
    }

    /**
     * api/locationtracker/
     */
    getFarmerSprayingLocationTrackerByUid(sprayingUid?) {
        return this.restangular.one(this.getSprayingUidLocationTrackerRoute, sprayingUid);
    }

    /**
     * api/farmers/
     */
    createSingleFarmer() {
        return this.restangular.all(this.getAllFarmersRoute);
    }

    /**
     * api/unassigned-bookingrequest-list
     */
    getAllUnassignedBookings() {
        return this.restangular.all(this.getAllMisscallBookingsRoute);
    }

    /**
     * api/booking-service-assignments
     */
    markBookingStatus(status) {
        return this.restangular.all(this.getAllMisscallBookingsRoute).one(status);
    }

    /**
     * api/update-bookingassignment-on-geography-update
     */
    updateBookingsAssignment() {
        return this.restangular.one(this.updateBookingsAssignmentRoute);
    }

    /**
     * api/get-product-points
     */
    getAllProductDataList() {
        return this.restangular.all(this.getAllProductDatsRoute);
    }

    /**
     * api/get-mdm-products
     */

    getAllMdmProducts() {
        return this.restangular.one(this.getllAllProductsOfMdmRoute);
    }

    /* 
    * api/products-by-material-code
     */
    getAllSimilarProducts() {
        return this.restangular.all(this.getAllSimilarProductsRoute);
    }

    /**
     * api/farmer-app-product-scan-details
     */
    saveProductScanPoints() {
        return this.restangular.all(this.saveProductScanPointsRoute);
    }

    /**
     * api/products
     */
    getAllProductList() {
        return this.restangular.all(this.getAllProductListRoute);
    }


    /**
     * api/crops
     */
    getAllCropsList() {
        return this.restangular.all(this.getAllCropsRoute);
    }

    /**
     * api/create-update-media
     */
    uploadMedia() {
        return this.restangular.all(this.uploadMediaRoute);
    }

    /**
     * api/bookingRequest
     */
    createBookingRequest() {
        return this.restangular.all(this.postBookingRequestRoute);
    }

    /**
     * api/booking-source-type
     */
    getAllBookingSourceTypes() {
        return this.restangular.all(this.getAllBookingSource);
    }

    /**
     * api/farmer-source-type
     */
    getAllFarmerSourceTypes() {
        return this.restangular.all(this.getAllFarmerSource);
    }

    /**
     * api/assigned-bookingrequest-list
     */
    getAllAssignedBookings() {
        return this.restangular.all(this.getAllAssignedBookingsRoute);
    }

    /**
     * api/assigned-bookingrequest-list
     */
    getUnAllAssignedBookings() {
        return this.restangular.all(this.getUnAllAssignedBookingsRoute);
    }

    /**
     * api/satellite/images/ndvi/farmer
     */
    calculateFarmerNDVI() {
        return this.restangular.all(this.calculateNDVIRoute);
    }

    /**
     * api/satellite/images/ndvi/farmer
     */
    getFarmerNDVIByGuid(farmerUID) {
        return this.restangular.all(this.calculateNDVIRoute).one(farmerUID);
    }

    getFarmerLandSoilMoistureByGuid(farmerUID) {
        return this.restangular.all(this.getFarmerLandSoilMoistureRoute).one(farmerUID);
    }

    /**
     * afs/api/product-by-barcode/{productCode}
     * @param qrCode
     */
    getProductDetailsByQRCode(qrCode) {
        return this.restangular.all(this.getProductByQRCodeRoute).one(qrCode);
    }

    getFarmerVoiceService() {
        return this.restangular.one(this.getFarmerVoiceServiceRoute);
    }

    /**
     * api/booking-service
     */
    getAllIncomingBookingRequests() {
        return this.restangular.all(this.getAllIncomingBookingsRoute);
    }

    /**
     * api/booking-analytics
     */
    getAllBookingAnalytics() {
        return this.restangular.one(this.getAllBookingAnalyticsRoute);
    }

    /**
     * api/booking-service/verify
     */
    verifySingleBooking() {
        return this.restangular.all(this.verifySingleBookingRoute);
    }

    /**
     * api/booking-service/invalidate
     */
    invalidateBookingRequest(requestId) {
        const invalidateURL = `${this.invalidateBookingRequestRoute}?requestId=${requestId}`;
        return this.restangular.all(invalidateURL);
    }

    /**
     * api/state-wise-spraying-count
     */
    getAllStateWiseSprayingCount() {
        return this.restangular.one(this.getStateWiseSprayingRequestRoute);
    }

    /**
     * api/spraying-activity
     */
    getAllSprayingRequest() {
        return this.restangular.all(this.getAllSprayingRequestRoute);
    }

    /**
     * api/spraying-activity/id
     */
    getSingleSprayingRequest(sprayingId) {
        return this.restangular.one(this.getAllSprayingRequestRoute, sprayingId);
    }

    /**
     * api/edit-spraying
     */
    updateSprayingRequest() {
        return this.restangular.one(this.updateSprayingRoute);
    }

    /**
     * api/spraying-product-images
     */
    getProductImage(sprayingId) {
        return this.restangular.one(this.getProductImageRoute, sprayingId);
    }

    /**
     * api/bookingRequest
     */
    getBookingStatusByMobileNumber() {
        return this.restangular.all(this.getAllActiveBookingStatusByMobileNumberRoute);
    }

    getAllSeedList() {
        return this.restangular.all(this.getAllSeedVarityRoute);
    }

    createNewBooking(bookingDTO) {
        return this.http.post(this.baseURL + this.getNewCreateBookingRoute, bookingDTO, {
            headers: this.createHeader()
        });
    }

    updateBooking(bookingDTO, isReleaseTagPresent): Observable<any> {
        return this.http.put(this.baseURL + this.getNewCreateBookingRoute, bookingDTO, {
            headers: isReleaseTagPresent ? this.createHeaderVersionV2() : this.createHeaderVersionV1()
        });
    }

    getAllBookingList() {
        return this.restangular.all(this.getAllListOfBookingsRoute);
    }

    getSelectedBookingByBookingId(bookingId, mobileNo) {
        return this.restangular.one(this.getSelectedBookingByBookingIDRoute).one(bookingId).one(mobileNo);
    }

    getFarmerByMobileNumberIfBookingNotAvailable(mobileNo) {
        return this.restangular.one(this.getFarmerByMobileNumberIfBookingNotAvailableRoute, mobileNo);
    }

    getFarmerByMobileNumberSearch(mobileNo) {
        return this.restangular.one(this.getFarmerByMobileNumberRoute, mobileNo);
    }

    /**
     *
     * @param bookingId
     * @param mobileNumber
     */
    getOneBookingByBookingIdAndMobileNumber(bookingId, mobileNumber) {
        return this.restangular.one(this.bookingBaseRoute, this.getOneBooking).one(bookingId, mobileNumber);
    }

    reassignBookingToOperator(bookingReassignDTO, isReleaseTagPresent): Observable<any> {
        return this.http.put(this.baseURL + this.reassignBookingToOperatorRoute, bookingReassignDTO, {
            headers: isReleaseTagPresent ? this.createHeaderVersionV2() : this.createHeaderVersionV1()
        });
    }

    getAllSeasonWiseCropAndSeed() {
        return this.restangular.all(this.getAllSeasonWiseCropAndSeedRoute);
    }

    getFarmerLandDetails(mobileNo) {
        return this.restangular.one(this.getFarmerLandsDetailsRoute, mobileNo);
    }

    exportBookingData() {
        return this.restangular.all(this.exportBookingDataToExcelRoute);
    }

    getAllActorsList() {
        return this.restangular.all(this.getAllActorsListRoute);
    }

    getAllReasonsList(reasonType) {
        return this.restangular.one(this.getAllReasonListRoute, reasonType);
    }

    sendBookingSMS(bookingDTO, isReleaseTagPresent) {
        return this.http.post(this.baseURL + this.sendBookingSMSRoute, bookingDTO, {
            headers: isReleaseTagPresent ? this.createHeaderVersionV2() : this.createHeaderVersionV1()
        });
    }

    getWalletInfo() {
        return this.restangular.all(this.getWalletInfoRoute);
    }

    getBillingInfo(bookingId, bookingServiceId, farmerMobileNo) {
        return this.restangular.one(this.getBillingDetailsInfoRoute + bookingId + '/'
            + bookingServiceId + '/' + farmerMobileNo);
    }

    /**
     * api/farmersmobile
     */
    getFarmerOTPByMobile(mobileNumber) {
        return this.restangular.one(this.getFarmersOTPByMobile, mobileNumber);
    }
    getSoilTestingCharges() {
        return this.restangular.all(this.getSoilTestingServiceCharges);
    }

    getServiceTypeByVillage() {
        return this.restangular.all(this.getServiceTypes);
    }

    getBookingStatusType() {
        return this.restangular.one(this.getBookingStatus);
    }

    getUploadXlssFile(farmerId, farmId, serviceId, bookingID, servicedAreaAcres, uuid) {
        let invalidateURL;
        if (uuid !== undefined) {
            invalidateURL = `${this.getUploadfile}?farmerId=${farmerId}&farmId=${farmId}&serviceId=${serviceId}&bookingId=${bookingID}&servicedAreaAcres=${servicedAreaAcres}&uuid=${uuid}`;
        } else {
            invalidateURL = `${this.getUploadfile}?farmerId=${farmerId}&farmId=${farmId}&serviceId=${serviceId}&bookingId=${bookingID}&servicedAreaAcres=${servicedAreaAcres}`;
        }
        return this.restangular.all(invalidateURL);

    }

    getUpload(excelData): Observable<HttpResponse<Object>> {
        return this.http.post(this.baseURL + this.uploadReport , excelData, {
            headers: this.createHeaderssss(), responseType: 'text', observe: 'response'
        }).pipe(
            tap(resp => console.log('response', resp)));
    }
    
    createHeaderssss() {
        return (new HttpHeaders({
            Authorization: `Bearer ${AuthenticationHelper.getToken()}`,
            'NF-Version': 'V1',
            'LABELS': '{"farmer":"f201","lat":"12.7","lng":"77.1"}',
            'TENANT': '301',
            'REFID': '68b8567f-4b2f-4d90-afc7-ad03ef01cdc6',
            'responseType': 'text',
        }));
    }

    getuploadImage(uuid) {
        const invalidateURL = `${this.getUploadImage}?action=${'GET_BLOB_BY_UUID'}&uuid=${uuid}`;
        return this.restangular.one(invalidateURL).withHttpConfig({ responseType: 'blob' });
    }

    getServiceStatusType() {
        return this.restangular.one(this.getServiceStatus);
    }

    /**
     * api/booking-service/verify
     */
    getSMSList() {
        return this.restangular.all(this.getSMSDetails);
    }

    /**
     * 'api/get-farmer-app-referral'
     */
    getFarmerreferalDetais(farmerId, pageNumber, limit) {
        const farmerReferralURL = `${this.getReferalDetais}?mobileNumber=${farmerId}&pageNumber=${pageNumber}&limit=${limit}`;
        return this.restangular.one(farmerReferralURL);
    }

    /**
     * 'api/ /get-booking-history'
     */
    getBookingLog(bookingId, farmerID) {
        return this.restangular.one(this.getFarmerBookingLog).one(bookingId, farmerID);
    }
    /**
     * 'api/ /get-booking-history'
     */
    getPictureStatusType() {
        return this.restangular.one(this.getPicturecategory);
    }

    getVerificationStatusType() {
        return this.restangular.one(this.getVerificationStatus);
    }

    getAgentInfo() {
        return this.restangular.one(this.getAgentdetails);
    }

    /**
     * api/get-farmer-active-insurance/
     */
    getInsuranceDetails(mobileNumber) {
        return this.restangular.one(this.getFarmerInsuranceDetails, mobileNumber);
    }

    getOnlineTransactionDetailsByFarmerId(farmerId, pageNumber, size) {
        const onlineTransactionDeatilsURL = `${this.getFarmerOnlineTransactionDeatils}${farmerId}?pageNumber=${pageNumber}&size=${size}`;
        return this.restangular.one(onlineTransactionDeatilsURL);
    }

    getGerminationInsuranceDetailByFarmerId(farmerId, pageNumber, size) {
        const germinationDeatilsURL = `${this.getGerminationTransactionDetailsRoute}${farmerId}?pageNumber=${pageNumber}&size=${size}`;
        return this.restangular.one(germinationDeatilsURL);
    }

    getPATransactionDetailsByFarmerId(farmerId, pageNumber, size) {
        const PaTransactionDeatilsURL = `${this.getPA_OnlineTransactionDeatils}${farmerId}?pageNumber=${pageNumber}&size=${size}`;
        return this.restangular.one(PaTransactionDeatilsURL);
    }

    getRejectionStatusType() {
        return this.restangular.one(this.getRejectionReason);
    }

    getAllPictureDetailList() {
        return this.restangular.all(this.getAllListOfPictureRoute);
    }

    getAllLandRecordDataLsit() {
        return this.restangular.all(this.getAllLandRecordRoute);
    }
    // getApprovalStatus() {
    //     return this.restangular.one(this.getApprovalStatusType);
    // }
    approvePictureStatus() {
        return this.restangular.all(this.approvePictureroute);
    }

    /**
     *'api/farmer-insurance/upload';
     */
    uploadFarmerFile(companyId) {
        return this.restangular.all(this.uploadFarmerInsuranceFileRoute).all(companyId);
    }

    uploadPaInsuarnce()  {
        return this.restangular.all(this.uploadPainsuarnceRoute);
    }

    uploadInsuranceFile(companyId) {
        const url = `${this.uploadInsuranceFileRoute}?type=${companyId}`;
        return this.restangular.all(url);
    }

    /**
     * api/farmer-manual-product-scan-details
     */

    FarmerScanDetail(farmerId, pageNumber, limit) {
        const url = `${this.getFarmerScandetail}?farmerMobileNumber=${farmerId}&pageNumber=${pageNumber}&limit=${limit}`;
        return this.restangular.one(url);
    }

    /**
     * 'api/get-donation-details'
     */
    fetchDonationDetails() {
        return this.restangular.one(this.getDnationDetails);
    }
    /**
     * 'api/video-feed/content-category-type'
     */
    getVideoCategories() {
        return this.restangular.one(this.getVideoCategory);
    }
    /**
     * 'api/video-feed/content-type'
     */
    geMimeType() {
        return this.restangular.one(this.geMimeTypeRoute);
    }

    /**
     * 'api/video-feed/verification-status-type'
     */
    getVideoVerificationStatus() {
        return this.restangular.one(this.getVideoVerificationStatusList);
    }

    /**
     * 'api/video-feed//program-type'
     */
    getProgramTypes() {
        return this.restangular.one(this.getProgramTypesRoute);
    }

    /**
     * 'api/video-feed/language-type'
     */
    getLanguageInfo() {
        return this.restangular.one(this.getLanguageDetails);
    }

    /**
     * 'afs/api/video-feed/verification-image-quality
    */

    getImageQualityInfo() {
        return this.restangular.one(this.getImageQaulityDetailsRoute);
    }

    /**
     * 'afs/api/video-feed/verification-crop-quality'
    */

    getCropQualityInfo() {
        return this.restangular.one(this.getCropQaulityDetailsRoute);
    }

    /**
     * 'afs/api/video-feed/damage-types'
    */
    getDamageTypeList() {
        return this.restangular.one(this.getDamageTypeListRoute);
    }

    /**
     * 'afs/api/video-feed/damage-names'
    */

    getDamageNamesList(type) {
        return this.restangular.all(this.getDamageNamesListRoute).one('damage-names', type);
    }

    /**
     * 'afs/api/video-feed/plant-part'
    */

    getPlantPartList() {
        return this.restangular.one(this.getPlantPartListRoute);
    }

    /**
     * 'afs/api/video-feed/crop-stages'
    */
    getCropStageList() {
        return this.restangular.one(this.getCropStageListRoute);
    }



    /**
     * 'api/video-feed/language-type'
     */
    getAllVideoDetailList() {
        return this.restangular.all(this.getAllVideoDetailRoute);
    }
    /**
     * 'api/video-feed/mark-content-status'
     */
    approveVideoStatus() {
        return this.restangular.all(this.approveVideoRoute);
    }

    getS3UrlForYouTubeVideo() {
        return this.restangular.all(this.getS3UrlForYtUpload);
    }

    getApprovarDetail(name) {
        const url = `${this.getApproverDetails}?name=${name}`;
        return this.restangular.one(url);
    }
    getAllCampaignList() {
        return this.restangular.all(this.getAllCampaignListRoute);
    }

    deleteCampaign(id) {
        const url = `${this.deleteCampaignRoute}?campaignId=${id}`;
        return this.restangular.one(url).remove();
    }

    restartCampaign(id) {
        const url = `${this.restartCampaignRoute}?campaignId=${id}`;
        return this.restangular.one(url).remove();
    }

    verifyAthenaQuery() {
        return this.restangular.all(this.verifyAthenaQueryRoute);
    }

    verifybannersAthenaQuery() {
        return this.restangular.all(this.verifyBannersAthenaQueryRoute);
    }

    createCampaign() {
        return this.restangular.all(this.createCampaignRoute);
    }

    testCampaign() {
        return this.restangular.all(this.testCampaignRoute);
    }

    uploadDynamicMediaType() {
        return this.restangular.all(this.uploadDynamicMediaTypeRoute);
    }

    getCampaignById(id) {
        return this.restangular.one(this.getCampaignRoute, id);
    }

    getTemplateList(payload) {
        return this.http.post(this.baseURL + this.getTemplateListRoute, payload, {
            headers: this.createHeaderForTemplates()
        });
    }

    updateCampaign() {
        return this.restangular.all(this.updateCampaignRoute);
    }

    testExistingCampaign(id) {
        const url = `${this.testExistingCampaignRoute}/${id}`;
        return this.restangular.all(url);
    }

    getallBannerList() {
        return this.restangular.all(this.getBannerListWithFilter);
    }
    uploadBannerImage(key) {
        const url = `${this.uploadbannerRoute}?key=${key}`;
        return this.restangular.all(url);
    }

    uploadLandTRecod(filename) {
        const url = `${this.uploadbannerRoute}?fileName=${filename}&key=en-us&type=land_record`;
        return this.restangular.all(url);
    }

    uploadbannerdetails() {
        return this.restangular.all(this.uploadbannerDetailsRoute);
    }

    getbannerdetails(id) {
        return this.restangular.one(this.getbannerDetailsRoute, id);
    }



    getPollsType() {
        return this.restangular.one(this.getPollsTypeRoute);
    }

    getStatusType() {
        return this.restangular.one(this.getPollsStatusRoute);
    }

    getPollsOptions() {
        return this.restangular.one(this.getPollsOptionsRoute);
    }

    getAllPollsDetailList() {
        return this.restangular.all(this.getAllPollsListRoute);
    }

    uploadPollsImage(key) {
        const url = `${this.uploadbannerRoute}?key='polls_quizz'`;
        return this.restangular.all(url);
    }

    addPollsDetails() {
        return this.restangular.all(this.createPollsListRoute);
    }

    getOpenOrders(farmerId) {
        const url = `${this.getFarmerOpenOrdersRoute}?farmerId=${farmerId}`;
        return this.restangular.one(url);
    }

    getInsurenceType() {
        return this.restangular.one(this.getInsurenceTypeList);
    }
    getBookingInvoiceDetails(farmerId, bookingId) {
        const url = `${this.getBookingInvoiceDetailsRoute}?farmerId=${farmerId}&bookingId=${bookingId}`;
        return this.restangular.one(url);
    }

    getLandReocordVerifiactionStatusList() {
        return this.restangular.one(this.getLandRecordStatusLst);
    }

    getLandRecordProgramTypes() {
        return this.restangular.one(this.geLandRecordProgramType);
    }

    getRejectStatusLandRecordReasons() {
        return this.restangular.one(this.getRejectLandRecordReasonsRoute);
    }

    updateLandRecord(){
        return this.restangular.all(this.updatelandRecordRout);
    }

    getGeoFencingStatus() {
        return this.restangular.one(this.getGeofencingStatusRoute);
    }

    getCampaignQueries(subQueryType) {
        return this.restangular.one(`${this.getCampaignQueriesRoute}/${subQueryType}`);
    }

    saveCampaignQueryToDB(query) {
        return this.restangular.all(this.saveQueryToDBRoute, query);
    }
    
    getAllOrdersList() {
        return this.restangular.all(this.getAllOrdersRoute);
    }

    getOrderById(orderId) {
        return this.restangular.one('/' + this.getOrderRoute, orderId);
    }

    getRejectionReasons() {
        return this.restangular.one(`/${this.getOrderRoute}/status/reasons`);
    }

    getOrderStatus() {
        return this.restangular.one(`/${this.getOrderRoute}/status`);
    }

    updateOrderAddress(updatedOrderData) {
        return this.http.patch(
          this.baseURL + this.getOrderRoute,
          updatedOrderData,
          {
            headers: this.createHeader(),
          }
        );
    }

    getBulkUploadInsuranceTypeList() {
        return this.restangular.one(this.getBulkUploadInsuranceListRoute);
    }

    getInsuranceUploadHistoryList() {
        return this.restangular.all(this.getinsuranceUploadHistoryRoute);
    }

    getPaginatedInsuranceUploadHistoryById() {
        return this.restangular.all(this.getPaginatedInsuranceUploadHistoryByIdRoute);
    }

    getUploadInsuranceHistoryById(id) {
        return this.restangular.one(this.getInsuranceUploadHistoryByIdRoute, id);
    }
    
    getBannerLanguageList() {
        return this.restangular.one(this.bannerlanguageRoute);
    }

    publishVedioApiRequest(payload) {
        let url = `${this.baseURL}api/video-feed/add-content-metadata`;
        return this.http.post(url , payload, {
            headers: this.createHeader() 
        });
    }

    uploadMediaFile(payload, fieName) {
        let url = `${this.baseURL}api/video-feed/upload-media?fileName=${fieName}`;
        return this.http.post(url , payload, {
            headers: this.createHeader() 
        });
    }
}
