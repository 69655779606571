import {Injectable, InjectionToken, Inject} from '@angular/core';
import {Restangular} from 'ngx-restangular';
import { environment } from '../../environments/environment';

//Restangular service that uses Bing
export const RESTANGULAR_REMOTE_SENSING = new InjectionToken<any>('RestangularRemoteSensing');
export function RestangularRemoteSensingFactory(restangular: Restangular) {
    return restangular.withConfig((RestangularConfigurer) => {
        RestangularConfigurer.setBaseUrl(environment.rsBaseUrl);
    });
}

@Injectable()
export class RemoteSensingService {
    baseRegionWiseNDVIRoute: any = '/api/region-wise-ndvis';
    baseCropProduction: any = 'api/district-wise-crop-product-resource';
    baseSoilMoistures: any = 'api/soil-moistures';
    baseAllCrops: any = 'api/crops';
    baseMandiByCrops: any = 'api/mandi/crop-prices';
    baseFarmerNDVIbyGeography: any = 'api/ndvis/getFarmersNdvi';
    baseFarmerSoilMoistures: any = 'api/soilmoisture/getFarmersSm';
    baseWeatherForcast: any = 'api/weather/forecast/daily';
    baseSoilHealth: any = 'api/soil-health-cards';
    calculateNDVIRoute: any = 'api/satellite/images/ndvi/farmer/';
    getFarmerLandSoilMoistureRoute: any = 'api/satellite/soilmoisture/farmer';

    constructor(@Inject(RESTANGULAR_REMOTE_SENSING) public restangularRemoteSensing, private restangular: Restangular) {

    }

    /**
     * /api/region-wise-ndvis
     */
    getRegionWiseNDVI() {
        return this.restangularRemoteSensing.all(this.baseRegionWiseNDVIRoute);
    }

    /**
     * To get the crop production data
     */
    getCropProduction() {
        return this.restangularRemoteSensing.all(this.baseCropProduction);
    }

    /**
     * To get the soil moistures data
     */
    getSoilMoistures() {
        return this.restangularRemoteSensing.all(this.baseSoilMoistures);
    }

    /**
     * To get the all crops data
     */
    getAllCrops() {
        return this.restangular.all(this.baseAllCrops);
    }

    /**
     * To get the mandi data by crops
     */
    getMandiByCrops() {
        return this.restangular.all(this.baseMandiByCrops);
    }

    /**
     * To get Farmer NDVI by geography
     */
    getFarmerNDVIbyGeography() {
        return this.restangular.all(this.baseFarmerNDVIbyGeography);
    }

    /**
     * To get Farmers Soil Moisture
     */
    getFarmersSoilMoisture() {
        return this.restangularRemoteSensing.all(this.baseFarmerSoilMoistures);
    }

    /**
     * To get weather forcast data by daily
     */
    getWeatherForecastDaily() {
        return this.restangular.all(this.baseWeatherForcast);
    }
    /**
     * To get weather forcast data by daily
     */
    getSoilHealthByRegion() {
        return this.restangular.one(this.baseSoilHealth);
    }

    /**
     * api/satellite/images/ndvi/farmer
     */
    getFarmerNDVIByGuid(farmerUID) {
        return this.restangularRemoteSensing.all(this.calculateNDVIRoute).one(farmerUID);
    }

    getFarmerLandSoilMoistureByGuid(farmerUID) {
        return this.restangularRemoteSensing.all(this.getFarmerLandSoilMoistureRoute).one(farmerUID);
    }
}