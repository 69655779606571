<div class="page-top clearfix" baScrollPosition maxHeight="50" (scrollChange)="scrolledChanged($event)"
     [ngClass]="{scrolled: isScrolled}">
    <div class="top-grid-container">
        <div class="top-grid-item" >
            <a href (click)="toggleMenu()" >
                <i class="fa fa-bars top-action-icon" aria-hidden="true"></i>
            </a>
        </div>
        <div class="top-grid-item">
            <img class="logo-img" (click)="navigateToDashboard()" src="assets/icons/nurture_farm_logo.png" alt="Image">
        </div>
        <div class="top-grid-item profile-and-name">
            <span [matMenuTriggerFor]="menu">
                <i class="fa fa-user-circle-o top-action-icon" aria-hidden="true"></i>
            </span>
            <span class="user-name-text">Hi, {{userName}}</span>
        </div>
    </div>
    <mat-menu #menu="matMenu">
        <ul class="no-padding-no-margin">
            <li  mat-menu-item *ngIf="isBOD" (click)="navigateToUserProfile()" class="list-item"><a ><i class="fa fa-user icon-size"></i>Profile</a></li>
            <li mat-menu-item (click)="loggedOff()" class="list-item"><a ><i class="fa fa-power-off icon-size"></i>Logout</a></li>
        </ul>
    </mat-menu>
</div>
