import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class AESEncryptDecryptService {

  secretKey = "bl@ckRose";

  // mthod for encrypt token
  encrypt(value){
    return CryptoJS.AES.encrypt(value, this.secretKey.trim()).toString();
  }

  // mthod for decrypt token
  decrypt(textToDecrypt){
    // for error handling if get any error while decrypt sending prsented token in local storage
    try {
      return CryptoJS.AES.decrypt(textToDecrypt, this.secretKey.trim()).toString(CryptoJS.enc.Utf8);
    } catch(error) {
      return textToDecrypt;
    }
  }
}