import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Restangular } from 'ngx-restangular';

@Injectable()
export class AuthenticationService {
  baseAccountRoute: any = "oauth/token";
  baseVerifyRoute: any = "api/verify-user/v1";
  baseUserAuthorityRoute: any = "api/user/getByToken";
  baseValidateOktaTokenRoute: any = "api/validate-okta-token";
  baseValidaterefreshTokenRoute: any = "api/refresh-token";
  authoritiesApiRoute: string = "api/authorities";
  removeAuthoritiesRoute: any = "api/remove-authorities";

  constructor(private restangular: Restangular, private http: HttpClient) { }
  baseURL: string = environment.baseUrl;
  
  createHeader(token) {
    return (new HttpHeaders({
      Authorization: `Bearer ${token}`,
      'X-Domain': "NURTURE_FARM"
      // 'NF-Service-Area-Id': localStorage.getItem('serviceAreaId')
    }));
  }

  validateOktaToken(token, payload) {
    let url = `${this.baseURL}api/validate-okta-token`;
    return this.http.post(url, payload, {
      headers: this.createHeader(token)
    });
  }

  /**
   * GET oauth/token
   */
  getAllLogin() {
    return this.restangular.all(this.baseAccountRoute);
  }
  /**
   * Post api/verify-user
   */
  verifyUser() {
    return this.restangular.all(this.baseVerifyRoute);
  }

  /**
   * api/user/getByToken
   */
  getUserByAuthority() {
    return this.restangular.one(this.baseUserAuthorityRoute);
  }

  /**
   * api/validate-okta-token
   */
  // validateOktaToken() {
  //   return this.restangular.all(this.baseValidateOktaTokenRoute);
  // }

  validateRefreshToken() {
    return this.restangular.all(this.baseValidaterefreshTokenRoute);
  }

  getAllAuthoritiesGroups() {
    return this.restangular.one(this.authoritiesApiRoute);
  }

  getAuthoritiesByUGDN(ugdn, isNewUser) {
    return this.restangular.one(
      `${this.authoritiesApiRoute}/${ugdn}/assigned?isNewUser=${isNewUser}`
    );
  }

  assignNewAuthorities() {
    return this.restangular.all(this.authoritiesApiRoute);
  }

  removeAuthority() {
    return this.restangular.all(this.removeAuthoritiesRoute);
  }
}
