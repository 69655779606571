import { Routes, RouterModule, PreloadAllModules, NoPreloading} from '@angular/router';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { environment } from '../environments/environment';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '**', redirectTo: 'home' }
];

// export const routing: ModuleWithProviders = RouterModule.forRoot(
//     routes, { useHash: false, preloadingStrategy: environment.production ?PreloadAllModules: NoPreloading}
// );

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }