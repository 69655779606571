export const PAGES_MENU = [
  {
    path: "users",
    key: "view_afs_users_tab",
    data: {
      menu: {
        title: " AFS Users",
        icon: "fa fa-users",
        selected: false,
        expanded: false,
        order: 0,
      },
    },
  },
  {
    path: "back-office",
    key: "view_back_office_tab",
    data: {
      menu: {
        title: " Back Office",
        icon: "fa fa-briefcase",
        selected: false,
        expanded: false,
        order: 0,
      },
    },
  },
  {
    path: "all-banner-details",
    key: "view_banners_tab",
    data: {
      menu: {
        title: " Banners",
        icon: "fa fa-picture-o",
        selected: false,
        expanded: false,
        order: 0,
      },
    },
  },
  {
    path: "bookings",
    key: "view_bookings_tab",
    data: {
      menu: {
        title: " Bookings",
        icon: "fa fa-adjust",
        selected: false,
        expanded: false,
        large: false,
        order: 0,
      },
    },
  },
  {
    path: "campaign",
    key: "ROLE_CAMPAIGN_MANAGEMENT",
    data: {
      menu: {
        title: " Campaign",
        icon: "fa fa-wpexplorer",
        selected: false,
        expanded: false,
        order: 0,
      },
    },
  },
  {
    path: "Communication-Info",
    key: "view_communication_info_tab",
    data: {
      menu: {
        title: " Communication-Info",
        icon: "fa fa-wpexplorer",
        selected: false,
        expanded: false,
        order: 0,
      },
    },
  },
  {
    path: "",
    key: "view_content_management_tab",
    data: {
      menu: {
        title: " Content Management",
        icon: "fa fa-database",
        selected: false,
        expanded: false,
        order: 0,
      },
    },
    children: [
      {
        path: "notice-board",
        data: {
          menu: {
            title: " Notice Board",
            icon: "fa fa-clipboard",
            selected: false,
            expanded: false,
            order: 0,
          },
        },
      },
      {
        path: "announcement",
        data: {
          menu: {
            title: " Announcement",
            icon: "fa fa-bullhorn",
            selected: false,
            expanded: false,
            order: 0,
          },
        },
      },
    ],
  },
  {
    path: "farmers",
    key: "view_farmer_tab",
    data: {
      menu: {
        title: " Farmer",
        icon: "fa fa-male",
        selected: false,
        expanded: false,
        order: 0,
      },
    },
  },
  {
    path: "video-verification",
    key: "role_view_video_tab",
    data: {
      menu: {
        title: " Feed UGC Verification(Video)",
        icon: "fa fa-file-video-o",
        selected: false,
        expanded: false,
        order: 0,
        large: true,
      },
    },
  },
  {
    path: "geographies",
    key: "view_geography",
    data: {
      menu: {
        title: " Geographies",
        icon: "fa fa-globe",
        selected: false,
        expanded: false,
        order: 0,
      },
    },
  },
  {
    path: "help",
    key: "view_help_tab",
    data: {
      menu: {
        title: " Help",
        icon: "fa fa-question-circle",
        selected: false,
        expanded: false,
        order: 0,
      },
    },
  },
  {
    path: "help-desk",
    key: "view_help_desk_tab",
    data: {
      menu: {
        title: " Help Desk",
        icon: "fa fa-ticket",
        selected: false,
        expanded: false,
        order: 0,
      },
    },
  },
  {
    path: "Upload-insurance-details",
    key: "view_insurance_upload",
    data: {
      menu: {
        title: " Insurance Upload",
        icon: "fa fa-upload",
        selected: false,
        expanded: false,
        order: 0,
      },
    },
  },
  {
    path: "upload-portal",
    key: "view_insurance_details_tab",
    data: {
      menu: {
        title: " Insurance Details",
        icon: "fa fa-medkit",
        selected: false,
        expanded: false,
        order: 0,
      },
    },
  },
  {
    path: "",
    key: "view_machine_tab",
    data: {
      menu: {
        title: " Machines",
        icon: "fa fa-automobile",
        selected: false,
        expanded: false,
        order: 0,
      },
    },
    children: [
      {
        path: "machines",
        data: {
          menu: {
            title: " Machines",
            icon: "fa fa-automobile",
            selected: false,
            expanded: false,
            order: 0,
          },
        },
      },
      {
        path: "machine-ble-mapping",
        data: {
          menu: {
            title: " BLE Mapping",
            icon: "fa fa-map",
            selected: false,
            expanded: false,
            order: 0,
          },
        },
      },
      {
        path: "controllers",
        data: {
          menu: {
            title: " Controllers",
            icon: "fa fa-building-o",
            selected: false,
            expanded: false,
            order: 0,
          },
        },
      },
      {
        path: "machine-village-mapping",
        data: {
          menu: {
            title: " Village Mapping",
            icon: "fa fa-globe",
            selected: false,
            expanded: false,
            order: 0,
          },
        },
      },
      {
        path: "spare-parts",
        data: {
          menu: {
            title: " Spare Parts",
            icon: "fa fa-steam",
            selected: false,
            expanded: false,
            order: 0,
          },
        },
      },
    ],
  },
  {
    path: "f3-data-upload",
    key: "view_partner_details_tab",
    data: {
      menu: {
        title: "Partner Details",
        icon: "fa fa-upload",
        selected: false,
        expanded: false,
        order: 0,
      },
    },
  },
  {
    path: "points-management",
    key: "view_points_management_tab",
    data: {
      menu: {
        title: " Points Management",
        icon: "fa fa-credit-card",
        selected: false,
        expanded: false,
        large: false,
        order: 0,
      },
    },
  },
  {
    path: "all-polls-details",
    key: "view_poll_and_quizzes_tab",
    data: {
      menu: {
        title: " Polls & Quizzes",
        icon: "fa fa-picture-o",
        selected: false,
        expanded: false,
        order: 0,
      },
    },
  },
  {
    path: "product-sales",
    key: "view_bookings_tab",
    data: {
      menu: {
        title: " Product Sales (D2F)",
        icon: "fa fa-adjust",
        selected: false,
        expanded: false,
        large: false,
        order: 0,
      },
    },
  },
  {
    path: "product-scan",
    key: "view_product_scan_details_tab",
    data: {
      menu: {
        title: " Product Scan Details",
        icon: "fa fa-qrcode",
        selected: false,
        expanded: false,
        order: 0,
      },
    },
  },
  {
    path: "remote-sensing",
    key: "view_remote_sensing_tab",
    data: {
      menu: {
        title: " Remote Sensing",
        icon: "fa fa-wpexplorer",
        selected: false,
        expanded: false,
        order: 0,
      },
    },
  },
  {
    path: "reports",
    key: "view_reports_tab",
    data: {
      menu: {
        title: " Reports",
        icon: "fa fa-file-text",
        selected: false,
        expanded: false,
        order: 0,
      },
    },
  },
  {
    path: "soil-testing",
    key: "view_soil_testing",
    data: {
      menu: {
        title: " Soil Testing",
        icon: "fa fa-globe",
        selected: false,
        expanded: false,
        order: 0,
      },
    },
  },
  {
    path: "picture-verification",
    key: "ROLE_PICTURE_VERIFICATION",
    data: {
      menu: {
        title: " Sustain Verification(Picture)",
        icon: "fa fa-picture-o",
        selected: false,
        expanded: false,
        order: 0,
        large: true,
      },
    },
  },
  {
    path: "state-budget",
    key: "VIEW_STATE_BUDGET",
    data: {
      menu: {
        title: " State Budget",
        icon: "fa fa-money",
        selected: false,
        expanded: false,
        order: 0,
      },
    },
  },
  {
    path: "tag-screenshots",
    key: "ROLE_PICTURE_VERIFICATION",
    data: {
      menu: {
        title: " Tag Screenshots",
        icon: "fa fa fa-mobile",
        selected: false,
        expanded: false,
        order: 0,
      },
    },
  },
  {
    path: "",
    key: "view_unimart_tab",
    data: {
      menu: {
        title: " Unimart",
        icon: "fa fa-universal-access",
        selected: false,
        expanded: false,
        order: 0,
      },
    },
    children: [
      {
        path: "unimart",
        data: {
          menu: {
            title: " Unimart Users",
            icon: "fa fa-universal-access",
            selected: false,
            expanded: false,
            order: 0,
          },
        },
      },
      {
        path: "unimart-outlets",
        data: {
          menu: {
            title: " Unimart Outlets",
            icon: "fa fa-code-fork",
            selected: false,
            expanded: false,
            order: 0,
          },
        },
      },
      {
        path: "unimart-employee-attendance-report",
        data: {
          menu: {
            title: " Attendance report",
            icon: "fa fa-file-text",
            selected: false,
            expanded: false,
            order: 0,
          },
        },
      },
    ],
  },
  {
    path: "user-tabs-mapping",
    key: "view_user_authority_mapping_tab",
    data: {
      menu: {
        title: " User-Tabs-Mapping",
        icon: "fa fa-credit-card",
        selected: false,
        expanded: false,
        large: false,
        order: 0,
      },
    },
  },

];
