import {Injectable} from '@angular/core';
import { getAnalytics, logEvent } from "firebase/analytics";
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthenticationHelper} from '../helpers/authentication';
import {UserRoleConstants} from '../constants/app.constant';
import * as _ from "lodash";

/**
 * LoginGuard to check if the user is logged in
 * return true or false
 */
@Injectable()
export class LoginGuard implements CanActivate {
    constructor(private route: Router) {
    }

    canActivate() {
        if (AuthenticationHelper.isLoggedIn()) {
            return true;
        } else {
            this.route.navigate(['']);
            return false;
        }
    }
}

/**
 * AuthGuard to check if the user is not logged in
 * return true or false
 */
@Injectable()
export class AuthGuard implements CanActivate {

    isInclude: boolean;

    constructor(private router: Router) {
    }

    canActivate() {
        let newLogggedInUser: any = AuthenticationHelper.getLoggedInUser();
        if (newLogggedInUser) {
            this.isInclude = _.some(newLogggedInUser.authorities, { 'serverId': UserRoleConstants.BDO });
        }

        let logggedInUser: any = AuthenticationHelper.getUsername();

        if (!AuthenticationHelper.isLoggedIn()) {
            return true;
        } else {
            if (logggedInUser == UserRoleConstants.SUPER_ADMIN ||
                logggedInUser == UserRoleConstants.ADMIN ||
                logggedInUser == UserRoleConstants.BDO ||
                logggedInUser == UserRoleConstants.BACK_OFFICE ||
                logggedInUser == UserRoleConstants.UTKARSH_USER ||
                logggedInUser == UserRoleConstants.BACK_OFFICE_FULL_ACCESS ||
                logggedInUser == UserRoleConstants.RS_USER ||
                logggedInUser == UserRoleConstants.SUPPORT ||
                logggedInUser == UserRoleConstants.AKC_USER) {
                if (logggedInUser == UserRoleConstants.BACK_OFFICE || this.isInclude) {
                    this.router.navigate(['/back-office/machine-report']);
                } else if (logggedInUser == UserRoleConstants.UTKARSH_USER) {
                    this.router.navigate(['/users']);
                } else if (logggedInUser == UserRoleConstants.SUPPORT) {
                    this.router.navigate(['/users']);
                } else if (logggedInUser == UserRoleConstants.RS_USER) {
                    this.router.navigate(['/remote-sensing']);
                } else if (logggedInUser == UserRoleConstants.AKC_USER) {
                    this.router.navigate(['/bookings']);
                } else if (logggedInUser == UserRoleConstants.SPECIFIC_USER) {
                    this.router.navigate(['/machines']);
                } else if (logggedInUser == UserRoleConstants.RETAILER_USER) {
                    this.router.navigate(['/geographies']);
                } else {
                    this.router.navigate(['dashboard']);
                }
            } else {
                localStorage.clear();
                this.router.navigate(['']);
            }
        }
    }
}

/**
 * AllUsersGuard to allow access for all users
 * return true or false
 */
@Injectable()
export class AllUsersGuard implements CanActivate {
    isInclude: boolean;

    constructor(private router: Router) {
    }

    canActivate() {

        let newLogggedInUser: any = AuthenticationHelper.getLoggedInUser();
        if (newLogggedInUser) {
            this.isInclude = _.some(newLogggedInUser.authorities, { 'serverId': UserRoleConstants.BDO });
        }

        let username: any = AuthenticationHelper.getUsername();

        let isSuperAdmin: boolean = username === UserRoleConstants.SUPER_ADMIN;
        let isAdmin: boolean = username === UserRoleConstants.ADMIN;
        let isBDO: boolean = this.isInclude;
        let isBackOffice: boolean = username === UserRoleConstants.BACK_OFFICE;
        let isBackOfficeFullAccess: boolean = username === UserRoleConstants.BACK_OFFICE_FULL_ACCESS;
        let isRSAccess: boolean = username === UserRoleConstants.RS_USER;
        let isTrainer: boolean = username === UserRoleConstants.TRAINER;
        let isCallCenterUser: boolean = username === UserRoleConstants.CALL_CENTER_USER;
        let saleuser: boolean = username === UserRoleConstants.SALE_USER;
        let afsBIUser: boolean = username === UserRoleConstants.AFS_BI_USER;
        let afsBIFirmwareUser: boolean = username === UserRoleConstants.AFS_BI_USER_FIRMWARE;
        let afsSupportUser: boolean = username === UserRoleConstants.SUPPORT;
        let akcUser: boolean = username === UserRoleConstants.AKC_USER;
        let nurtureMetricUser: boolean = username === UserRoleConstants.NURTURE_METRICS_USER;
        let specificUser: boolean = username === UserRoleConstants.SPECIFIC_USER;
        let retailUser: boolean = username === UserRoleConstants.NURTURE_RETAIL_USER;

        if (isSuperAdmin || isBDO || isBackOffice || isAdmin || isBackOfficeFullAccess
            || isRSAccess || isTrainer || isCallCenterUser || saleuser || afsBIUser || afsBIFirmwareUser
            || afsSupportUser || akcUser || nurtureMetricUser || specificUser || retailUser) {
            return true;
        } else {
            this.router.navigate(['']);
            return false;
        }
    }
}

/**
 * SuperAdminGuard to allow access to only superadmin user
 * return true or false
 */
@Injectable()
export class SuperAdminGuard implements CanActivate {
    constructor(private router: Router) {
    }

    canActivate() {
        let username: any = AuthenticationHelper.getUsername();
        let isSuperAdmin: boolean = username == UserRoleConstants.SUPER_ADMIN;
        let isBackOfficeFullAccess: boolean = username == UserRoleConstants.BACK_OFFICE_FULL_ACCESS;

        if (isSuperAdmin || isBackOfficeFullAccess) {
            return true;
        } else {
            this.router.navigate(['']);
            return false;
        }
    }
}

/**
 * point credit menu guard to allow access to only point credit user
 * return true or false
 */
@Injectable()
export class PointCreditGuard implements CanActivate {
    isInclude: boolean;
    isIncludeSuperadmin: boolean;
    isIncludeRoleL1Tech: boolean;
    isIncludeRoleL1NonTech: boolean;
    isIncludeSuperadminWithCampaign: boolean;
    constructor(private router: Router) {
    }

    canActivate() {
        let newLogggedInUser: any = AuthenticationHelper.getLoggedInUserDetails();
        if (newLogggedInUser) {
            this.isInclude = _.some(newLogggedInUser.authorities, { 'authority': UserRoleConstants.POINT_CREDIT_MENU });
            this.isIncludeRoleL1Tech = _.some(newLogggedInUser.authorities, { 'authority': UserRoleConstants.ROLE_L1_TECH_PRIMARY_MENU });
            this.isIncludeRoleL1NonTech = _.some(newLogggedInUser.authorities, { 'authority': UserRoleConstants.ROLE_L1_NON_TECH });
        }
        if (newLogggedInUser) {
            this.isIncludeSuperadmin = _.some(newLogggedInUser.authorities, { 'authority': UserRoleConstants.ROLE_SUPERADMIN });
            this.isIncludeSuperadminWithCampaign = _.some(newLogggedInUser.authorities, {
              authority: UserRoleConstants.ROLE_SUPERADMIN_WITH_CAMPAIGN,
            });
        }
        // let username: any = AuthenticationHelper.getUsername();
        // let isPointCredit: boolean = username == UserRoleConstants.POINT_CREDIT_MENU;

        if (
          this.isInclude ||
          this.isIncludeSuperadmin ||
          this.isIncludeRoleL1Tech ||
          this.isIncludeRoleL1NonTech ||
          this.isIncludeSuperadminWithCampaign
        ) {
          return true;
        } else {
          this.router.navigate([""]);
          return false;
        }
    }
}

/**
 * BDOSuperAdminGuard to check if the user is superadmin
 * return true or false
 */
@Injectable()
export class BDOSuperAdminGuard implements CanActivate {

    isInclude: boolean;

    constructor(private router: Router) {
    }

    canActivate() {
        let newLogggedInUser: any = AuthenticationHelper.getLoggedInUser();
        if (newLogggedInUser) {
            this.isInclude = _.some(newLogggedInUser.authorities, { 'serverId': UserRoleConstants.BDO });
        }

        let username: any = AuthenticationHelper.getUsername();
        let isSuperAdmin: boolean = username == UserRoleConstants.SUPER_ADMIN;
        let isAdmin: boolean = username == UserRoleConstants.ADMIN;
        // let isBDO: boolean = this.isInclude;
        let isBDO: boolean = username == UserRoleConstants.BDO;
        let isBackOfficeFullAccess: boolean = username == UserRoleConstants.BACK_OFFICE_FULL_ACCESS;
        let isUtkarshUser: boolean = username == UserRoleConstants.UTKARSH_USER;
        let isTrainer: boolean = username == UserRoleConstants.TRAINER;
        let isCallCenterUser: boolean = username === UserRoleConstants.CALL_CENTER_USER;

        if (isSuperAdmin || isBDO || isAdmin || isBackOfficeFullAccess || isUtkarshUser
            || isTrainer || isCallCenterUser) {
            return true;
        } else {
            this.router.navigate(['']);
            return false;
        }
    }
}

/**
 * BDOSuperAdminGuard to check if the user is superadmin
 * return true or false
 */
@Injectable()
export class SupportUserGuard implements CanActivate {

    isInclude: boolean;

    constructor(private router: Router) {
    }

    canActivate() {

        let newLogggedInUser: any = AuthenticationHelper.getLoggedInUser();
        if (newLogggedInUser) {
            this.isInclude = _.some(newLogggedInUser.authorities, { 'serverId': UserRoleConstants.BDO });
        }

        let username: any = AuthenticationHelper.getUsername();
        let isSuperAdmin: boolean = username == UserRoleConstants.SUPER_ADMIN;
        let isAdmin: boolean = username == UserRoleConstants.ADMIN;
        // let isBDO: boolean = this.isInclude;
        let isBDO: boolean = username == UserRoleConstants.BDO;
        let isBackOfficeFullAccess: boolean = username == UserRoleConstants.BACK_OFFICE_FULL_ACCESS;
        let isUtkarshUser: boolean = username == UserRoleConstants.UTKARSH_USER;
        let isTrainer: boolean = username == UserRoleConstants.TRAINER;
        let isSupport: boolean = username == UserRoleConstants.SUPPORT;
        let isAkcUser: boolean = username == UserRoleConstants.AKC_USER;
        let specificUser: boolean = username === UserRoleConstants.SPECIFIC_USER;
        let retailerUser: boolean = username === UserRoleConstants.RETAILER_USER;

        if (isSuperAdmin || isBDO || isAdmin || isBackOfficeFullAccess || isUtkarshUser || isTrainer
            || isSupport || isAkcUser || specificUser || retailerUser) {
            return true;
        } else {
            this.router.navigate(['']);
            return false;
        }
    }
}

@Injectable()
export class SaleUserGuard implements CanActivate {
    isInclude: boolean;

    constructor(private router: Router) {
    }

    canActivate() {
        let username: any = AuthenticationHelper.getUsername();
        let newLogggedInUser: any = AuthenticationHelper.getLoggedInUser();
        if (newLogggedInUser) {
            this.isInclude = _.some(newLogggedInUser.authorities, { 'serverId': UserRoleConstants.BDO });
        }
        let saleUser: boolean = username === UserRoleConstants.SALE_USER;
        let afsBIUser: boolean = username === UserRoleConstants.AFS_BI_USER;
        let afsBIFirmwareUser: boolean = username === UserRoleConstants.AFS_BI_USER_FIRMWARE;

        if (saleUser || afsBIUser || afsBIFirmwareUser) {
            return true;
        } else {
            this.router.navigate(['']);
            return false;
        }
    }
}

// Guard to restrict user to access only assigned tabs
@Injectable()
export class AuthorizationGuard implements CanActivate {
  isInclude: boolean;

  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const logggedInUser: any = AuthenticationHelper.getLoggedInUserDetails();
    if(route && route.data && route.data.key) {
        if(logggedInUser && logggedInUser.authorities) {
            const arr = logggedInUser.authorities.filter(
              ({ authority }) => authority === route.data.key
            );
            if(arr.length) {
              const analytics = getAnalytics();
              logEvent(analytics, "screen_view", {
                firebase_screen: route.routeConfig.path,
                firebase_screen_class: "screen_view",
              });
              return true;
            } else {
                this.router.navigate([""]);
                return false;
            }
        }
    } else {
        this.router.navigate([""]);
        return true;
    }
  }
}
