import {Injectable} from '@angular/core';
import {Restangular} from 'ngx-restangular';

@Injectable()
export class GeographyService {
    baseGeographyRoute: any = 'api/geographies';
    baseGETGeographyRoute: any = 'api/get-geographies';
    baseServiceableVillageRoute: any = 'api/serviceable-villages';
    baseServiceableGeographiesRoute: any = 'api/geographies-serviceable/parentId';
    baseServiceableVillageByDistrictIdRoute: any = 'api/geographies-serviceable/villages/districtId';
    baseServiceableMachineByVillageRoute: any = 'api/operator-by-village';
    getParentId: any = 'api/geographies/get-state';
    getOperatorDetailsRoutes: any = 'api/booking/reassign/operator-list';

    constructor(private restangular: Restangular) {

    }

    /**
     * /api/geographies/geographies
     */
    getAllGeographies() {
        return this.restangular.all(this.baseGeographyRoute);
    }

    /**
     * /api/geographies/geographies/{geographyId}
     */
    getSingleGeography(geographyId) {
        return this.restangular.one(this.baseGeographyRoute, geographyId);
    }

    /**
     * /api/geographies/getByGeoTypeId/{geoTypeId}
     */
    getAllGeographiesByGeoType (geoTypeId){
        return this.restangular.all(this.baseGeographyRoute).one('getByGeoTypeId', geoTypeId);
    }

    /**
     * /api/geographies/getByStateId/{stateId}
     */
    getAllGeographiesByState (stateId){
        return this.restangular.all(this.baseGeographyRoute).one('getByStateId', stateId);
    }

    /**
     * /api/geographies/getByParentId/{parentId}
     */
    getAllGeographiesByParentId (parentId){
        return this.restangular.all(this.baseGeographyRoute).one('getByParentId', parentId);
    }

    /**
     * /api/geographies/getByParentId/{parentId}
     */
    getGeographiesByMultipleParentId (){
        return this.restangular.all(this.baseGeographyRoute).all('getMultipleByParentId');
    }

    /**
     * /api/geographies/getVillagesByStateIdByPagination/{stateId}
     */
    getAllVillagesByStateId (stateId){
        return this.restangular.all(this.baseGeographyRoute).one('getVillagesByStateIdByPagination', stateId);
    }

    /**
     * /api/geographies/geographies
     */
    getAllGeographiesList() {
        return this.restangular.one(this.baseGETGeographyRoute);
    }

    /**
     * api/serviceable-villages
     * @param tehsilId
     */
    getAllBookingServiceableVillage(tehsilId) {
        return this.restangular.one(this.baseServiceableVillageRoute, tehsilId);
    }

    getAllServiceableGeography(geographyId) {
        return this.restangular.one(this.baseServiceableGeographiesRoute, geographyId);
    }

    getAllServiceableVillageByDistrictId(geographyId) {
        return this.restangular.one(this.baseServiceableVillageByDistrictIdRoute, geographyId);
    }

    getAllServiceableMachineByVillage(geographyId) {
        return this.restangular.one(this.baseServiceableMachineByVillageRoute, geographyId);
    }

    /**
     * api/geographies/get-state/
     * @param Village id
     */
    getParentIdByVillage(parentId) {
        return this.restangular.one(this.getParentId, parentId);
    }

    /**
     *  'api/booking/reassign/operator-list'
     *
     */
    getoperatorDetails() {
        return this.restangular.all(this.getOperatorDetailsRoutes);
    }
}
