import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Routes, Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { BaMenuService } from '../../services';
import { GlobalState } from '../../../helpers/global.state';
import { AuthenticationService } from "../../../app-services/authentication-service";
import { AuthenticationHelper } from "../../../helpers/authentication";
import { PAGES_MENU } from 'app/pages/pages.menu';
import { ToastrService } from 'ngx-toastr';
import { MsalServiceService } from 'app/helpers/msal-service.service';

@Component({
  selector: 'ba-menu',
  templateUrl: './baMenu.html',
  styleUrls: ['./baMenu.scss']
})
export class BaMenu {

  @Input() sidebarCollapsed: boolean = false;
  @Input() menuHeight: number;

  @Output() expandMenu = new EventEmitter<any>();

  public menuItems: any[];
  protected _menuItemsSub: Subscription;
  public showHoverElem: boolean;
  public hoverElemHeight: number;
  public hoverElemTop: number;
  protected _onRouteChange: Subscription;
  public outOfArea: number = -200;
  activeMenu: any;
  userRoles = [];

  constructor(private _router: Router, private _service: BaMenuService, private _state: GlobalState,
    private authService: AuthenticationService,
    private newAuthService: MsalServiceService, public toastr: ToastrService) {

  }

  public updateMenu(newMenuItems) {
    this.menuItems = newMenuItems;
    this.selectMenuAndNotify();
  }

  public selectMenuAndNotify(): void {
    this.activeMenu = this._router.url.split('/')[1];
    if (this.menuItems) {
      this.menuItems.forEach((item, index) => {
        item.children && item.children.length ?
          this.setChildSelected(item) : item.route.path == this.activeMenu ?
            item['isChildSelected'] = true : item['isChildSelected'] = false;
      });
      this.menuItems = this._service.selectMenuItem(this.menuItems);
      this._state.notifyDataChanged('menu.activeLink', this._service.getCurrentItem());
    }
  }

  public setChildSelected(item) {
    item.children.map((childMenu) => {
      if (childMenu.route.path == this.activeMenu) {
        setTimeout(() => {
          item.selected = true;
          item.expanded = true;
          childMenu.selected = true;
        }, 20);
      } else {
        childMenu.selected = false;
      }
    });
  }

  public ngOnInit(): void {
    this._onRouteChange = this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.menuItems) {
          this.selectMenuAndNotify();
          this.getUserAuthority();
        } else {
          // on page load we have to wait as event is fired before menu elements are prepared
          setTimeout(() => this.selectMenuAndNotify());
        }
      }
    });

    this._menuItemsSub = this._service.menuItems.subscribe(this.updateMenu.bind(this));
  }

  getUserAuthority() {
    this.userRoles = [];
    this.authService.getUserByAuthority().get().subscribe(res => {
      let userData = JSON.parse(localStorage.getItem('userData'));
      let oldAuthorities = []
      for (let data of res.authorities) {
        this.userRoles.push(data.authority);
      }
      for (let data of userData.authorities) {
        oldAuthorities.push(data.authority)
      }
      if (oldAuthorities.sort().join(',') !== this.userRoles.sort().join(',')) {
        localStorage.removeItem('userData');
        userData['roles'] = [];
        userData['roles'] = this.userRoles;
        userData['authorities'] = [];
        userData['authorities'] = res.plain().authorities;
        AuthenticationHelper.setUser(userData);
        if (res.authorities && !res.authorities.length) {
          this.logOut();
        } else {
          if (AuthenticationHelper.checkUserHasAnyMDMAuthourityOrNot(res.authorities)) {
            if (oldAuthorities.length > res.authorities.length) {
              let url = this._router.url;
              url = url.split('/')[1];
              if (!this.checkUserHashTabAccess(url, res.authorities)) {
                this.toastr.error("Access Denied");
                this._router.navigate(['/dashboard']);
              }
            }
            this.upadateSideMenu();
          } else {
            this.logOut();
          }
        }
      }
    });
  }

  checkUserHashTabAccess(url, authorities) {
    let authorityKeyName = "";
    PAGES_MENU.forEach(element => {
      if (url == element.path) {
        authorityKeyName = element.key;
        return;
      }
    });

    let HashAuthority = false;

    authorities.forEach(element => {
      if (element.authority == authorityKeyName) {
        HashAuthority = true;
        return;
      }
    });

    return HashAuthority;
  }

  logOut() {
    localStorage.clear();
    this.toastr.error("Access Denied");
    this.toastr.success("Logged Out successfully");
    this.newAuthService.logout();
    // this.oktaAuth.logout("/afs-admin/home");
  }

  checkUserhasAuthority

  upadateSideMenu() {
    let loggedInUser = AuthenticationHelper.getLoggedInUserDetails();
    if (loggedInUser && loggedInUser.authorities) {
      this._service.updateMenuByRoutes(<Routes>this.createMenu());
    }
  }

  createMenu() {
    const dashboard = {
      path: "dashboard",
      data: {
        menu: {
          title: " Dashboard",
          icon: "fa fa-dashboard",
          selected: false,
          expanded: false,
          order: 0,
        },
      },
    };
    let loggedInUser = AuthenticationHelper.getLoggedInUserDetails();
    let assinedAuthorities;
    if (loggedInUser && loggedInUser.authorities) {
      assinedAuthorities = PAGES_MENU.filter(({ key }) =>
        loggedInUser.authorities.some(
          ({ authority }) => key.trim() === authority.trim()
        )
      );
    }
    assinedAuthorities.splice(0, 0, dashboard);
    return assinedAuthorities;
  }

  public ngOnDestroy(): void {
    this._onRouteChange.unsubscribe();
    this._menuItemsSub.unsubscribe();
  }

  public hoverItem($event): void {
    this.showHoverElem = true;
    this.hoverElemHeight = $event.currentTarget.clientHeight;
    // TODO: get rid of magic 66 constant
    this.hoverElemTop = $event.currentTarget.getBoundingClientRect().top - 66;
  }

  public toggleSubMenu($event): boolean {
    let submenu = jQuery($event.currentTarget).next();

    if (this.sidebarCollapsed) {
      this.expandMenu.emit(null);
      if (!$event.item.expanded) {
        $event.item.expanded = true;
      }
    } else {
      $event.item.expanded = !$event.item.expanded;
      submenu.slideToggle();
    }

    return false;
  }
}
