import { Injectable } from "@angular/core";
@Injectable()
export class UtilityHelper {

    private constructor() {
    }

    /**
     * To convert the string in Title case
     * @param str
     * @returns {LoDashExplicitWrapper<string>|string|any|LodashReplace1x3|void}
     */
    static toTitleCase(str) {
        return str.replace(/\w\S*/g, (txt) => {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    }

    /**
     * To trim the values of the form controls
     * @param form
     * @param value
     * @param control
     * @returns {string|any|LoDashExplicitWrapper<string>}
     */
    static trimContent(form, value, control) {
        if (value) {
            form.controls[control].setValue(value.trim());
        }
        return value.trim();
    }

    /**
     * To trim the values of the form controls
     * @param value
     * @returns {string|any|LoDashExplicitWrapper<string>}
     */
    static trimValue(value) {
            return value && value.trim() ? value.trim() : null;
    }

    /**
     * To validate and allow only numbers
     * @param event
     * @returns {boolean}
     */
    static validateNumber(event) {
        let ctrlKey = 17, vKey = 86, cKey = 67;
        const charCode = (event.which) ? event.which : event.keyCode;
        if ((charCode < 8 || charCode > 57) && event.keyCode != ctrlKey) {
            return false;
        }
        return true;
    }

    /**
     * To get NDVI Map Legends
     */
    static getNDVILegends(legendId, isSoilMoisture?) {
        let legends = {
            Nice: {
                name: isSoilMoisture ? 'Soil Moisture (0.6 - 1.0)':'High NDVI (0.6 - 1.0)',
                color: '#009104'
            },
            Good: {
                name: isSoilMoisture ? 'Soil Moisture (0.3 - 0.6)':'Good NDVI (0.3 - 0.6)',
                color: '#a8e100'
            },
            Bad: {
                name: isSoilMoisture ? 'Soil Moisture (0.0 - 0.2)':'Bad NDVI (0.0 - 0.2)',
                color: '#fbea2e'
            }
        };

        let legendElement = legendId;
        isSoilMoisture ?
            legendElement.innerHTML = `<strong id="land-title">Soil Moisture</strong>` : legendElement.innerHTML = `<strong id="land-title">Land Health</strong>`;
        for (let key in legends) {
            let type = legends[key];
            let name = type.name;
            let color = type.color;
            let div:any = document.createElement('div');
            div.style = 'padding:5px';
            div.innerHTML = '<span class="ndvi-bands" style="background: '+ color + ';"></span>' + name;
            legendElement.appendChild(div);
        }

        return legendElement;
    }

    /**
     * To return the array of Geo Types data
     * @returns array of Geo Types data
     */
    static getGeoTypes() {
        return [
            {"name": "District", "id": "3", "parent_geo_id": "2", "code": "GEO004"},
            {"name": "Tehsil", "id": "4", "parent_geo_id": "3", "code": "GEO005"},
            {"name": "Village", "id": "5", "parent_geo_id": "4", "code": "GEO006"},
        ]
    }

}