import { Injectable } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { BaThemeSpinner } from "../../app/theme/services/baThemeSpinner";
import { AuthenticationHelper } from './authentication';
import { Restangular } from "ngx-restangular";
import { AuthenticationService } from 'app/app-services/authentication-service';
import { MsalServiceService } from './msal-service.service';

@Injectable({
    providedIn: "root"
})
export class Errorhandle {

    private constructor(
        public toastr: ToastrService,
        private _spinner: BaThemeSpinner,
        private restangular: Restangular,
        private newAuthService: MsalServiceService,
        private authService: AuthenticationService,
    ) {
    }

    handleError(err) {
        this._spinner.hide();
        if (err && err?.status == 401) {
            this.getRefreshToken();
        } else if (err?.status == 400) {
            this.toastr.error(this.checkBadRequestOrNoDataAvailableMessage(err))
        } else if (err?.status == 403) {
            this.toastr.error(err?.error?.detail ? err?.error?.detail : err?.error?.title ?
                err?.error?.title : "Access is denied."
            );
        } else if (err?.status == 404) {
            this.toastr.error(this.checkNotFoundMessage(err));
        } else if (err?.status == 412) {
            this.toastr.error(err?.error?.data?.status?.status ? err.error?.data?.status?.status : "BAO_INPUT");
        } else if (err?.status == 500) {
            this.toastr.error(this.checkInternalServerMessage(err));
        } else if (err?.status == 501) {
            this.toastr.error(err?.statusText ? err?.statusText : "Service Unavailable");
        } else if (err?.status == 503) {
            this.toastr.error(err?.statusText);
        } else {
            this.toastr.error("Some error occurred please open console or network mode to see");
        }
    }

    checkBadRequestOrNoDataAvailableMessage(err) {
        if (err?.error?.title) {
            return err?.error?.title;
        } else if (err?.error?.responseMessage) {
            return err?.error?.responseMessage;
        } else if (err?.error?.detail) {
            return err?.error?.detail;
        } else {
            return "Bad Request";
        }
    }

    checkNotFoundMessage(err) {
        if (err?.error?.responseMessage) {
            return err?.error?.responseMessage;
        } else if (err?.error?.message) {
            return err?.error?.message;
        } else if (err?.error?.statusText) {
            return err?.error?.statusText;
        } else {
            return "Not Found";
        }
    }

    checkInternalServerMessage(err) {
        if(err?.error?.detail) {
            return err?.error?.detail;
        } else if(err?.error?.title) {
            return err?.error?.title;
        } else if(err?.statusText) {
            return err?.statusText;
        } else {
            return "Something went wrong."
        }
    }

    getRefreshToken() {
        let refreshToken = AuthenticationHelper.getRefreshToken();
        this.restangular.provider.setDefaultHeaders({ 'authorization': refreshToken });
        this.authService.validateRefreshToken().post().subscribe(res => {
            localStorage.removeItem('isTokenExpire');
            localStorage.removeItem('token');
            localStorage.removeItem('refresh_token');
            AuthenticationHelper.setToken(res?.access_token)
            AuthenticationHelper.setRefreshToken(res?.refresh_token)
            window.location.reload();
        }, errorResponse => {
            console.log('errorResponse', errorResponse);
            this.toastr.error('Session Expired');
            localStorage.clear();
            this.newAuthService.logout();
        });
    }
}