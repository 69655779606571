import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BaThemeSpinner} from './theme/services';
import { ContactUsConstants } from './constants/app.constant';

@Component({
    styleUrls: ['./callback.component.scss'],
    template: `
        <div class="main-section" *ngIf="showHideErrorMessage">
            <div class="navbar-header">
                <img class="logo-image" src="https://in.uplonline.com/images/logo.png" alt="Image">

            </div>

            <div>
                <p class="heading">
                    <i class="fa fa-exclamation-triangle alert-color" aria-hidden="true"></i>You do not have access for the MDM.
                    Please go through the following points for getting access.
                </p>
            </div>

            <div>
                <p class="contact">
                    1. After logged in to the OKTA check the below app (highlighted in red border) 'Nurture MDM prod' shows up in
                    the okta dashboard.
                </p>
                <div>
                    <img src="assets/img/MDM_Access.png" class="accessImg" alt="Image">
                </div>
                <p class="contact">2. If the above app does not show in the okta home dashboard please click on “Service Now” app
                    in the okta home dashboard and raise the IT request by following the below points.</p>
                <div class="subpoints">
                    <ul>
                        <li>From Okta Launchpad click on “Service Now” app</li>
                        <li>Once navigated to <a href="https://uplonline.service-now.com/sp" target="_blank">https://uplonline.service-now.com/sp</a></li>
                        <li>Click on “Service Catalogue”</li>
                        <li>Choose item “AD / Email”</li>
                        <li>In Request Type Choose “DL Group Create”</li>
                        <li>In Description :
                            Please add UGDN :<> to groups Nurture-mdm-users in AD
                            <p class="contact">- replace <> with your UGDN</p>

                        </li>
                    </ul>
                </div>
                <p class="contact">3. If approval is required, drop a mail to <a href="mailto: {{email}}" target="_blank">{{email}}</a> for approval. Once approve attach the same to the ticket.</p>
                <p class="contact">4. Post the request is fulfilled, please check if the above app (highlighted in red arrow) shows up in the okta dashboard.</p>
                <p class="contact">
                    5. If you have access for the Nurture MDM prod in the okta home dashboard page and not able to login on the MDM
                    Please drop an email to <a
                        href="mailto: {{email}}?subject=Onboarding%20MDM&body=Please%20provide%20MDM%20access%20to%20this%20{UGDN}"
                        target="_blank">{{email}}</a> with your UGDN for the access
                </p>
            </div>

        </div>`
})
export class CallbackComponent implements OnInit {
    isQueryParams: any;
    showHideErrorMessage = false;
    error: string;
    email: String = ContactUsConstants.contactEmail;

    constructor(
                private activeRoute: ActivatedRoute,
                private spinner: BaThemeSpinner,
                private router: Router) {
    }

    async ngOnInit(): Promise<void> {
        this.spinner.hide();
        this.isQueryParams = this.activeRoute.snapshot.queryParams['error'];
        if (!this.isQueryParams) {
            this.showHideErrorMessage = false;
            // Handles the response from Okta and parses tokens
            // await this.okta.handleAuthentication();
            await this.router.navigate(['/home']);
        } else {
            this.showHideErrorMessage = true;
        }
    }
}
