import {Injectable} from '@angular/core';
import {Restangular} from 'ngx-restangular';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Observable} from "rxjs";
import { AuthenticationHelper } from 'app/helpers/authentication';

@Injectable()
export class UserService {
    baseBDORoute: any = 'api/business-development-officers';
    getAllBDORoute: any = 'api/bdo-v1';
    baseFORoute: any = 'api/field-officers';
    getAllFORoute: any = 'api/fo-v1';
    baseOperatorRoute: any = 'api/operators';
    getAllOperatorsRoute: any = 'api/operators-v1';
    baseUploadOperatorsRoute: any = 'api/uploadOperators';
    uploadOperatorsRoute: any = 'api/uploadOperators';
    getChildEmployeesRoute: any = 'api/get-child-employees';
    getBDOChildEmployeesRoute: any = 'api/getfieldOfficersByBDOId';
    getFOChildEmployeesRoute: any = 'api/getOperatorsByFO';
    getBDOOperatorsRoute: any = 'api/operators/getByBDOAndSearchKeyword';
    getActiveMachinesByBDORoute: any = 'api/machine-operator-mappings/getActiveMappingsByBDO';
    getUnassignedMachinesByBDORoute: any = 'api/machines/getUnAssignedMachinesByBDO';
    uploadMediaRoute: any = 'api/create-update-media';
    getSprayingActivityDetailsRoute = 'api/spraying-activity-details-by-user-id';
    getChildEmployeesMultipleParentsRoute = 'api/get-child-employees-multiple-parents';
    getListOfFarmersRoute: any = 'api/farmers-list';
    getAllAFSEmployeesRoute: any = 'api/searchEmployee';
    getMatchingEmployeesRoute: any = 'api/getMatchingEmployees';
    getAllBDOByStateRoute: any = 'api/business-development-officers-by-geographyid';
    getAllFOByStateRoute: any = 'api/field-officers-by-state';
    getAllHelpDeskRoute: any = 'api/get-help-desks-list';
    getSingleHelpDeskRoute: any = 'api/help-desks';
    getSingleHelpDeskMediaRoute: any = 'api/helpdesk-images';
    getSprayingActivityDetailRouteV1: any = 'api/spraying-activity-details-by-user-id/v1';
    getListOfFarmersUnderOperatorRoute: any = 'api/farmers-list-v1';
    getOperatorHistoryDetailRoute: any = 'api/operator-history';
    getOverallOperatorSummaryRoute: any = 'api/get-operator-summary-v1';
    getExpenseRoute: any = 'api/expense-pageable/v1';
    getExpenseImageRoute: any = 'api/expense-images';
    getAllBankListRoute: any = 'api/bank-masters';
    getAllListOfCompanyRoute: any = 'api/company';
    getAllSalesEmployeeBasedOnRoleRoute: 'api/sales-employee';
    sbuHeadRoute: any = 'api/sbu-head';
    createsbuHeadRoute: any = 'api/sbu-heads';
    UpdatesbuHeadRoute: any = 'api/sbu-heads';
    zonalSalesManagerRoute: any = 'api/zonal-sales-managers';
    zonalMarketingManagersRoute: any = 'api/zonal-marketing-managers';
    zonalServiceManagersRoute: any = 'api/zonal-service-managers';
    territoryManagerRoute: any = 'api/territory-managers';
    clsuterInchargeRoute: any = 'api/cluster-incharges';
    promoteUserRoute: any = 'api/promote-user';
    enableDisableBookingUIRoute: any = 'api/booking/booking-enable';
    assistantBDORoute: any = 'api/assistant-business-development-officers';
    licenseRejectionListRoute: any = 'api/dl-status-reason-list';
    bannerlanguageRoute: any = 'api/banners/get-languages';

    baseURL: string = environment.baseUrl;
    constructor(private restangular: Restangular, private http: HttpClient) {

    }
    createHeaderWithServiceArea() {
        return (new HttpHeaders({
            Authorization: `Bearer ${AuthenticationHelper.getToken()}`,
            'NF-Service-Area-Id': localStorage.getItem('serviceAreaId')
        }));
    }

    createHeaderWithoutServiceArea() {
        return (new HttpHeaders({
            Authorization: `Bearer ${AuthenticationHelper.getToken()}`
        }));
    }

    /**
     * api/business-development-officers
     */
    getAllBDO() {
        return this.restangular.all(this.baseBDORoute);
    }

    /**
     * api/business-development-officers/{userId}
     */
    getSingleBDO(userId) {
        return this.restangular.one(this.baseBDORoute, userId);
    }

    /**
     * api/field-officers
     */
    getAllFO() {
        return this.restangular.all(this.baseFORoute);
    }

    /**
     * api/field-officers/{userId}
     */
    getSingleFO(userId) {
        return this.restangular.one(this.baseFORoute, userId);
    }

    /**
     * api/operators
     */
    getAllOperator() {
        return this.restangular.all(this.baseOperatorRoute);
    }

    /**
     * api/operators/{userId}
     */
    getSingleOperator(userId) {
        return this.restangular.one(this.baseOperatorRoute, userId);
    }

    /**
     * api/uploadOperators
     */
    uploadOperators() {
        return this.restangular.all(this.uploadOperatorsRoute);
    }

    /**
     * api/activeOperators/{userId}
     */
    getSingleOperatorActive(userId) {
        return this.restangular.one(this.baseOperatorRoute).one('activate', userId);
    }

    /**
     * api/activeFo/{userId}
     */
    getSingleFOActive(userId) {
        return this.restangular.one(this.baseFORoute).one('activate', userId);
    }

    /**
     * api/activeBDO/{userId}
     */
    getSingleBDOActive(userId) {
        return this.restangular.one(this.baseBDORoute).one('activate', userId);
    }

    /**
     * api/get-child-employees
     */
    getAllChildEmployees() {
        return this.restangular.one(this.getChildEmployeesRoute);
    }

    /**
     * api/getfieldOfficersByBDOId
     */
    getBDOChildEmployees() {
        return this.restangular.all(this.getBDOChildEmployeesRoute);
    }

    /**
     * api/getOperatorsByFO
     */
    getFOChildEmployees() {
        return this.restangular.all(this.getFOChildEmployeesRoute);
    }

    /**
     * api/getByBDOAndSearchKeyword
     */
    getBdoOperatorsEmployees() {
        return this.restangular.all(this.getBDOOperatorsRoute);
    }

    /**
     * api/machine-operator-mappings/getActiveMappingsByBDO
     */
    getActiveMappingsByBDO() {
        return this.restangular.all(this.getActiveMachinesByBDORoute);
    }

    /**
     * api/machines/getUnAssignedMachinesByBDO
     */
    getUnassignedMachinesByBDO() {
        return this.restangular.all(this.getUnassignedMachinesByBDORoute);
    }

    /**
     * api/create-update-media
     */
    uploadMedia() {
        return this.restangular.all(this.uploadMediaRoute);
    }

    /**
     * api/spraying-activity-details-by-user-id
     */
    getAllSprayingActivityDetails(userId) {
        return this.restangular.one(this.getSprayingActivityDetailsRoute, userId);
    }

    /**
     * api/get-child-employees-multiple-parents
     */
    getChildEmployeesMultipleparents() {
        return this.restangular.all(this.getChildEmployeesMultipleParentsRoute);
    }

    /**
     * api/farmers-list
     */
    getALLFarmersUnderOperator(operatorId) {
        return this.restangular.one(this.getListOfFarmersRoute, operatorId);
    }

    /**
     * api/getAllEmployees
     */
    getAllAFSEmployees() {
        return this.restangular.all(this.getAllAFSEmployeesRoute);
    }

    /**
     * api/getMatchingEmployees
     */
    getAllMatchingEmployees() {
        return this.restangular.one(this.getMatchingEmployeesRoute);
    }

    /**
     * api/business-development-officers-by-geographyid
     */
    getAllBDOByState(geographyId) {
        return this.restangular.one(this.getAllBDOByStateRoute, geographyId);
    }

    /**
     * api/field-officers-by-geographyid
     */
    getAllFOByState(geographyId) {
        return this.restangular.one(this.getAllFOByStateRoute, geographyId);
    }

    /**
     * api/bdo-v1
     */
    getALLPaginatedBDO() {
        return this.restangular.all(this.getAllBDORoute);
    }

    /**
     * api/fo-v1
     */
    getALLPaginatedFO() {
        return this.restangular.all(this.getAllFORoute);
    }

    /**
     * api/operators-v1
     */
    getALLPaginatedOperator() {
        return this.restangular.all(this.getAllOperatorsRoute);
    }

    /**
     * api/get-help-desks-list
     */
    getAllHelpDeskList() {
        return this.restangular.all(this.getAllHelpDeskRoute);
    }

    /**
     * api/get-help-desks-list
     */
    getSingleHelpDeskTicket() {
        return this.restangular.one(this.getSingleHelpDeskRoute);
    }

    /**
     * api/helpdesk-images
     */
    getSingleHelpDeskMedia() {
        return this.restangular.one(this.getSingleHelpDeskMediaRoute);
    }

    /**
     * api/spraying-activity-details-by-user-id/v1
     */
    getAllSprayingActivityDetailsByUserId(userId) {
        return this.restangular.one(this.getSprayingActivityDetailRouteV1, userId);
    }

    /**
     * api/farmers-list-v1
     */
    getALLFarmersListUnderOperator() {
        return this.restangular.all(this.getListOfFarmersUnderOperatorRoute);
    }

    /**
     * api/operator-history
     */
    getOperatorHistory(operatorId) {
        return this.restangular.one(this.getOperatorHistoryDetailRoute, operatorId);
    }

    /**
     * api/get-operator-summary-v1
     */
    getOverallOperatorHistory() {
        return this.restangular.one(this.getOverallOperatorSummaryRoute);
    }

    /**
     * api/expense-pageable
     */
    getOperatorExpense() {
        return this.restangular.all(this.getExpenseRoute);
    }

    /**
     * api/expense-images
     */
    getExpenseImage() {
        return this.restangular.one(this.getExpenseImageRoute);
    }

    /**
     * api/bank-masters
     */
    getAllBankList() {
        return this.restangular.all(this.getAllBankListRoute);
    }

    /**
     * api/company
     */
    getListOfCompany() {
        return this.restangular.all(this.getAllListOfCompanyRoute);
    }

    /**
     * api/sales-employee
     */
    getAllSalesEmployee() {
        return this.restangular.all('api/sales-employee');
    }

    /**
     * api/sbu-heads
     */
    sbuHead() {
        return this.restangular.all(this.createsbuHeadRoute);
    }
    getSingleSBUHead(userId) {
        return this.restangular.one(this.sbuHeadRoute, userId);
    }
    UpdateSingleSBUHead(userId) {
        return this.restangular.one(this.UpdatesbuHeadRoute, userId);
    }
    getSingleActiveSBUHead(userId) {
        return this.restangular.one(this.sbuHeadRoute).one('activate', userId);
    }

    /**
     * api/zonal-sales-managers
     */
    zonalSalesManager() {
        return this.restangular.all(this.zonalSalesManagerRoute);
    }
    getSingleZonalSalesManager(userId) {
        return this.restangular.one(this.zonalSalesManagerRoute, userId);
    }
    getSingleActiveZonalSalesManager(userId) {
        return this.restangular.one(this.zonalSalesManagerRoute).one('activate', userId);
    }

    /**
     * api/zonal-marketing-managers
     */
    zonalMarketingManager() {
        return this.restangular.all(this.zonalMarketingManagersRoute);
    }
    getSingleZonalMarketingManager(userId) {
        return this.restangular.one(this.zonalMarketingManagersRoute, userId);
    }
    getSingleActiveZonalMarketingManager(userId) {
        return this.restangular.one(this.zonalMarketingManagersRoute).one('activate', userId);
    }

    /**
     * api/zonal-service-managers
     */
    zonalServiceManager() {
        return this.restangular.all(this.zonalServiceManagersRoute);
    }
    getSingleZonalServiceManager(userId) {
        return this.restangular.one(this.zonalServiceManagersRoute, userId);
    }
    getSingleActiveZonalServiceManager(userId) {
        return this.restangular.one(this.zonalServiceManagersRoute).one('activate', userId);
    }

    /**
     * api/territory-managers
     */
    territoryManager() {
        return this.restangular.all(this.territoryManagerRoute);
    }
    getSingleTerritoryManager(userId) {
        return this.restangular.one(this.territoryManagerRoute, userId);
    }
    getSingleActiveTerritoryManager(userId) {
        return this.restangular.one(this.territoryManagerRoute).one('activate', userId);
    }

    /**
     * api/cluster-incharges
     */
    clusterIncharge() {
        return this.restangular.all(this.clsuterInchargeRoute);
    }
    getSingleClusterIncharge(userId) {
        return this.restangular.one(this.clsuterInchargeRoute, userId);
    }
    getSingleActiveClusterIncharge(userId) {
        return this.restangular.one(this.clsuterInchargeRoute).one('activate', userId);
    }

    promoteUserToNewRole(userDetails, isServiceAreaAvailable): Observable<any> {
        return this.http.post(this.baseURL + this.promoteUserRoute, userDetails, {
           headers: isServiceAreaAvailable ? this.createHeaderWithServiceArea() : this.createHeaderWithoutServiceArea()
        });
    }

    /**
     * api/booking/booking-enable
     */
    getEnableDisableBookingUI(isCheckboxChecked, userId) {
        return this.restangular.one(this.enableDisableBookingUIRoute + '/' + isCheckboxChecked + '/' + userId);
    }

    /**
     * api/assistant-business-development-officers
     */
    assistantBDO() {
        return this.restangular.all(this.assistantBDORoute);
    }
    getSingleAssistantBDO(userId) {
        return this.restangular.one(this.assistantBDORoute, userId);
    }
    getSingleActiveAssistantBDO(userId) {
        return this.restangular.one(this.assistantBDORoute).one('activate', userId);
    }

    getlicenseRejectionList() {
        return this.restangular.one(this.licenseRejectionListRoute);
    }

    getBannerLanguageList() {
        return this.restangular.one(this.bannerlanguageRoute);
    }
}
