import { NgModule }      from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BaBackTop, BaContentTop, BaMenuItem, BaMenu, BaPageTop, BaSidebar } from './components';
import { BaScrollPosition, BaSlimScroll } from './directives';
import { BaMenuService, BaThemePreloader, BaThemeSpinner } from './services';
import { EmailValidator, EqualPasswordsValidator } from './validators';
import {MatMenuModule} from '@angular/material/menu';

const NGA_COMPONENTS = [
    BaBackTop,
    BaContentTop,
    BaMenuItem,
    BaMenu,
    BaPageTop,
    BaSidebar
];

const NGA_DIRECTIVES = [
    BaScrollPosition,
    BaSlimScroll,
];

const NGA_SERVICES = [
    BaThemePreloader,
    BaThemeSpinner,
    BaMenuService
];

const NGA_VALIDATORS = [
    EmailValidator,
    EqualPasswordsValidator
];

@NgModule({
    declarations: [
        ...NGA_DIRECTIVES,
        ...NGA_COMPONENTS
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule.withConfig({
         callSetDisabledState: 'whenDisabledForLegacyCode',
        }),
        MatMenuModule
    ],
    exports: [
        ...NGA_DIRECTIVES,
        ...NGA_COMPONENTS,
    ],
    providers: [
        ...NGA_VALIDATORS,
        ...NGA_SERVICES
      ],
})

export class NgaModule {
}
