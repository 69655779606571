import { Component, ChangeDetectorRef, OnInit } from '@angular/core';
import { GlobalState } from './helpers/global.state';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../environments/environment';
import { AuthenticationHelper } from "./helpers/authentication";
import { AuthenticationService } from "./app-services/authentication-service";
import * as _ from "lodash";
import { NavigationEnd, Router, RouterEvent } from "@angular/router";
import {  Errorhandle } from 'app/helpers/errorHandler';

@Component({
    selector: 'app',
    styleUrls: ['./app.component.scss'],
    template: `
    <main [ngClass]="{'menu-collapsed': isMenuCollapsed}">
      <div class="additional-bg"></div>
      <router-outlet></router-outlet>
    </main>
  `
})
export class App implements OnInit {

    isMenuCollapsed: boolean = false;
    userRoles = [];

    constructor(
        private cdRef: ChangeDetectorRef,
        private _state: GlobalState,
        public toastr: ToastrService,
        private authService: AuthenticationService,
        private router: Router,
        private errorhandle: Errorhandle
    ) {

        this._state.subscribe('menu.isCollapsed', (isCollapsed) => {
            this.isMenuCollapsed = isCollapsed;
        });
        if ((router.url === '/' || router.url === '')) {
            this.router.events.subscribe((event: RouterEvent) => {
                if (event instanceof NavigationEnd) {
                    if (!(event.url).includes('farmer') || !(event.url).includes('invoice')) {
                        if (event.url == '/Crm-dashboard') {
                            this.router.navigate(['/Crm-dashboard']);
                        }
                        // else {
                        //     this.router.navigate(['/home']);
                        // }
                    }
                }
            });
        }
    }

    async ngOnInit() {
        window.scrollTo(0, 0);
        // this.getUserAuthority();
        let data = localStorage.getItem('isTokenExpire');

        if (data == 'true') {
           this.errorhandle.getRefreshToken();
        }
    }


    getUserAuthority() {
        this.authService.getUserByAuthority().get().subscribe(res => {
            let userData = JSON.parse(localStorage.getItem('userData'));
            localStorage.removeItem('userData');
            delete userData.roles;
            for (let data of res.authorities) {
                this.userRoles.push(data.authority);
            }
            userData['roles'] = this.userRoles;
            userData['authorities'] = res.plain().authorities;
            AuthenticationHelper.setUser(userData);
        });
    }
    ngAfterViewChecked() {
        this.cdRef.detectChanges();
        if (environment.production) {
            if (window) {
                window.console.log = () => { }
            }
        }
    }
}
