import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgaModule} from '../theme/nga.module';
import {PagesRoutingModule} from './pages.routing';
import {Pages} from './pages.component';
import { SmsInfoComponent } from './sms-info/sms-info.component';
@NgModule({
    imports: [CommonModule, NgaModule, PagesRoutingModule],
    declarations: [Pages]
})

export class PagesModule {
}
