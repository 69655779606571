import {Injectable} from '@angular/core';
import {Restangular} from 'ngx-restangular';

@Injectable()
export class ReportService {
    sprayingReportRoute:any = 'api/populateSprayingDataReport';
    machineReportRoute:any = 'api/populateMachineDataReport';
    machineReportRouteByDate:any = 'api/machine-report';
    updateMachineReportsRoute:any = 'api/machine-reports';
    viewMachineReportsRoute:any = 'api/view-machine-report';
    viewBDOReportsRoute:any = 'api/bdo-manual-report';

    stateWiseFalconReportRoute:any = 'api/populateStatewiseReportFalconMachine';
    stateWiseAurbusReportRoute: any = 'api/populateStatewiseReportAurbusMachine';

    districtWiseFalconReportRoute: any = 'api/populateDistrictwiseReportFalconMachine';
    districtWiseAurbusReportRoute: any = 'api/populateDistrictwiseReportAurbusMachine';

    baseSprayingActivityReportSummaryForAurbusMachineRoute: any = 'api/populateSprayingActivityReportSummaryForAurbusMachine';
    baseSprayingActivityReportSummaryForFalconMachineRoute: any = 'api/populateSprayingActivityReportSummaryForFalconMachine';

    baseMachineReportSummaryForFalconMachine: any = 'api/populateMachineReportSummaryFalcon';
    baseMachineReportSummaryForAurbusMachine: any = 'api/populateMachineReportSummaryAurbus';
    baseFOBDOReport: any = 'api/populateFOBDOReport';

    baseEmployeeSalary: any = 'api/employee-salary-record';
    updateBaseEmployeeSalaryRoute: any = 'api/employee-salary-records';
    machineReportMediaRoute: any = 'api/upload-media-files-machine-report';
    manualFOEntryRoute: any = 'api/manual-entry-reports-mdm-v2';
    uploadMediaMachineMaintenance: any = 'api/upload-media-files';
    machineMaintenanceRequest: any = 'api/machine-maintenance-request';

    getAllSparePartsRoute: any = 'api/search-spare-parts';
    updateMachineSparePartsRoute: any = 'api/update-spare-parts';

    salaryRecordExportToExcelRoute = 'api/employee-salary-record-export-to-xls';
    uploadEmployeeSalaryRecordsRoute = 'api/uploadEmployeeSalaryRecords';
    uploadFOManualRecordsRoute = 'api/uploadFOManualReport';

    exportToEmailSprayingReportsRoute = 'api/async-reports/spraying_activity_report';
    exportToEmailFOBDOReportsRoute = 'api/async-reports/fo_bdo_report';
    exportToEmailMachineReportsRoute = 'api/async-reports/machine_report';
    exportToEmailSalaryReportsRoute = 'api/async-reports/salary_report';
    exportToEmailFOManualReportsRoute = 'api/async-reports/fo_manual_report';

    uploadMachineReportRoute = 'api/uploadMachineReport';
    exportMachineReportRoute = 'api/machine-report-export-to-xls';

    uploadBDOManualReportRoute = 'api/uploadBDOManualReporting';
    firmwareDashboardReportRoute = 'api/quicksight/get-quicksight-dashboard-url';

    constructor(private restangular: Restangular) {

    }

    /**
     * api/populateSprayingDataReport
     */
    getAllSprayingActivities() {
        return this.restangular.all(this.sprayingReportRoute);
    }

    /**
     * api/populateMachineDataReport
     */
    getMachineReport() {
        return this.restangular.all(this.machineReportRoute);
    }

    /**
     * api/populateStatewiseReportFalconMachine
     */
    getStateWiseFalconReport() {
        return this.restangular.all(this.stateWiseFalconReportRoute);
    }

    /**
     * api/populateStatewiseReportAurbusMachine
     */
    getStateWiseAurbusReport() {
        return this.restangular.all(this.stateWiseAurbusReportRoute);
    }

    /**
     * api/populateDistrictwiseReportFalconMachine
     */
    getDistrictWiseFalconReport() {
        return this.restangular.all(this.districtWiseFalconReportRoute);
    }

    /**
     * api/populateDistrictwiseReportAurbusMachine
     */
    getDistrictWiseAurbusReport() {
        return this.restangular.all(this.districtWiseAurbusReportRoute);
    }

    /**
     * api/populateSprayingActivityReportSummaryForAurbusMachine
     */
    getAllSprayingActivityReportSummaryForAurBusMachine() {
        return this.restangular.all(this.baseSprayingActivityReportSummaryForAurbusMachineRoute);
    }

    /**
     * api/populateSprayingActivityReportSummaryForFalconMachine
     */
    getAllSprayingActivityReportSummaryForFalconMachine() {
        return this.restangular.all(this.baseSprayingActivityReportSummaryForFalconMachineRoute);
    }

    /**
     * api/populateMachineReportSummaryForFalconMachine
     */
    getAllMachineReportSummaryForFalconMachine() {
        return this.restangular.all(this.baseMachineReportSummaryForFalconMachine);
    }

    /**
     * api/populateMachineReportSummaryForFalconMachine
     */
    getAllMachineReportSummaryForAurbusMachine() {
        return this.restangular.all(this.baseMachineReportSummaryForAurbusMachine);
    }

    /**
     * api/populateFOBDOReport
     */
    getFOBDOWiseReport() {
        return this.restangular.all(this.baseFOBDOReport);
    }

    /**
     * api/getAllMachineReportByDate
     */
    uploadMachineReportMedia() {
        return this.restangular.all(this.machineReportMediaRoute);
    }

    /**
     * api/upload-media-files
     */
    uploadMachineMaintenanceMedia() {
        return this.restangular.all(this.uploadMediaMachineMaintenance);
    }

    /**
     * api/machine-report
     */
    getAllMachineReportsByFilters() {
        return this.restangular.all(this.machineReportRouteByDate);
    }

    /**
     * api/employee-salary-record
     */
    getSalaryReportByFilters() {
        return this.restangular.all(this.baseEmployeeSalary);
    }

    /**
     * api/employee-salary-records
     */
    getUpdateSalaryReport() {
        return this.restangular.all(this.updateBaseEmployeeSalaryRoute);
    }

    /**
     * api/machine-reports
     */
    getUpdateMachineReports () {
        return this.restangular.all(this.updateMachineReportsRoute);
    }

    /**
     * api/manual-entry-reports-mdm*
     */
    getManualFOEntryReport() {
        return this.restangular.all(this.manualFOEntryRoute);
    }

    /**
     * api/manual-entry-reports-mdm*
     */
    getMachineMaintenanceRequest() {
        return this.restangular.all(this.machineMaintenanceRequest);
    }

    /**
     * api/manual-entry-reports-mdm*
     */
    getMachineMaintenanceRequestDetails(machineId) {
        return this.restangular.one(this.machineMaintenanceRequest, machineId);
    }

    /**
    * api/spare-parts
    */
    getAllSpareParts() {
        return this.restangular.one(this.getAllSparePartsRoute);
    }

    /**
     * api/update-spare-parts
     */
    addMachineSparePartList() {
        return this.restangular.all(this.updateMachineSparePartsRoute);
    }

    /**
     * api/view-machine-report
     */
    getAllMachineReportView() {
        return this.restangular.all(this.viewMachineReportsRoute);
    }

    /**
     * api/bdo-manual-report
     */
    getAllBDOReportView() {
        return this.restangular.all(this.viewBDOReportsRoute);
    }

    /**
     * api/view-machine-report
     */
    getAllSalaryRecordToExcel() {
        return this.restangular.all(this.salaryRecordExportToExcelRoute);
    }

    /**
     * api/uploadEmployeeSalaryRecords
     */
    uploadEmployeeSalaryRecords() {
        return this.restangular.all(this.uploadEmployeeSalaryRecordsRoute);
    }

    /**
     * api/uploadFOManualReport
     */
    uploadFOManualRecords() {
        return this.restangular.all(this.uploadFOManualRecordsRoute);
    }

    /**
     * api/async-reports/spraying_activity_report
     */
    exportToEmailSprayingReports() {
        return this.restangular.all(this.exportToEmailSprayingReportsRoute);
    }

    /**
     * api/async-reports/fo_bdo_report
     */
    exportToEmailFOBDOReports() {
        return this.restangular.all(this.exportToEmailFOBDOReportsRoute);
    }

    /**
     * api/async-reports/machine_report
     */
    exportToEmailMachineReports() {
        return this.restangular.all(this.exportToEmailMachineReportsRoute);
    }

    /**
     * api/async-reports/salary_report
     */
    exportToEmailSalaryReports() {
        return this.restangular.all(this.exportToEmailSalaryReportsRoute);
    }

    /**
     * api/async-reports/fo_manual_report
     */
    exportToEmailFOManualReports() {
        return this.restangular.all(this.exportToEmailFOManualReportsRoute);
    }

    /**
     * api/uploadMachineReport
     */
    uploadMachineRecords() {
        return this.restangular.all(this.uploadMachineReportRoute);
    }

    /**
     * api/machine-report-export-to-xls
     */
    exportMachineReport() {
        return this.restangular.all(this.exportMachineReportRoute);
    }

    /**
     * api/uploadBDOManualReporting
     */
    uploadBDOManualReport() {
        return this.restangular.all(this.uploadBDOManualReportRoute);
    }

    /**
     * api/quicksight/firmware-dashboard-url
     */
    getFirmwareDashboardURL() {
        return this.restangular.all(this.firmwareDashboardReportRoute);
    }
}
