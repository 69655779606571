import {Component} from '@angular/core';
import {GlobalState} from '../../../helpers/global.state';
import {GlobalEvents} from "../../../helpers/global.events";

@Component({
    selector: 'ba-content-top',
    styleUrls: ['./baContentTop.scss'],
    templateUrl: './baContentTop.html',
})
export class BaContentTop {

    public activePageTitle: string = '';

    constructor(private _state: GlobalState, private events: GlobalEvents) {
        this._state.subscribe('menu.activeLink', (activeLink) => {
            this.activePageTitle = activeLink.title;
        });
    }

    ngAfterViewInit() {
        this.events.getChangedContentTopText().subscribe(item => {
            if (item) {
                this.activePageTitle = item;
            }
        });
    }
}
