import { Injectable, Inject, InjectionToken} from '@angular/core';
import { Restangular } from 'ngx-restangular';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AuthenticationHelper } from 'app/helpers/authentication';

//Restangular service that uses Bing
export const RESTANGULAR_UNIMART = new InjectionToken<any>('RestangularUnimart');
export function RestangularUnimartFactory(restangular: Restangular) {
    return restangular.withConfig((RestangularConfigurer) => {
        RestangularConfigurer.setBaseUrl(environment.unimartBaseUrl);
    });
}

@Injectable()
export class UnimartService {

    getAllActiveEmployeesRoute: any = 'api/getAllActiveEmployees';
    getDateWiseTripsRoute: any = 'api/date-wise-trips-employeeId';
    getEmployeeTripsRoute: any = 'api/tripuid-wise-trip-activity-route';
    getTripWiseTripMeetingsRoute: any = 'api/tripuid-wise-trip-meetings';
    getMeetingActivityMediaRoute: any = 'api/meeting-activity';
    getClusterHeadRoute: any = 'api/cluster-heads';
    getExtensionManagersRoute: any = 'api/extension-managers';
    getExtensionOfficersRoute: any = 'api/extension-officers';
    getRetailOfficersRoute: any = 'api/retail-officers';
    getEmployeesBasedOnRoleRoute: any = 'api/getEmployeesBasedOnRole';
    getAllOutletRoute: any = 'api/unimart-outlet/getAll';
    getFarmerMeetingHistoryRoute: any = 'api/farmer-history-pageable';
    getUnimartDashboardReportRoute: any = 'api/dashboard-report-mdm/v1';
    getUnimartSalesDashboardReportRoute: any = 'api/dashboard-report-sales-outlet-wise-drill-down';
    getUnimartOutletWiseRoute: any = 'api/dashboard-report-mdm-outlet-wise';
    getAllUnimartOutletListRoute: any = 'api/unimart-outlet/getAllOutlets';
    createUnimartOutletRoute: any = 'api/unimart-outlet/outlets';
    getSingleUnimartOutletRoute: any = 'api/unimart-outlet/get/byCode/';
    getUnimartFenceReportRoute: any = 'api/fence-report';
    getAllUnimartOutletResourceRoute: any = 'api/unimart-outlet/outlet-mdm';
    getClusterHeadActiveRoute: any = 'api/clusterHead/activate';
    getExtensionManagerActiveRoute: any = 'api/extensionManager/activate';
    getRetailOfficerActiveRoute: any = 'api/retailerOfficer/activate';
    getExtensionOfficerActiveRoute: any = 'api/extensionOfficer/activate';
    getSingleOutletRoute: any = 'api/unimart-outlet/unimart-outlet';
    getOutletMappedUserRoute: any = 'api/unimart-outlet/outlet-mapped-employee';
    getTripDetailsBasedOnOutletRoute: any = 'api/date-wise-trips-employeeId/v3';
    getDashboardReportOutletWiseRoute: any = 'api/dashboard-report-outlet-wise-drill-down';
    getSalesExecutiveByOutletRoute: any = 'api/truepos/salesExecutive/getByOutlet';
    getSyncSaleExecutivesRoute: any = 'api/truepos/salesExecutive/sync';
    getChildUsersRoute: any = 'api/getEmployees';
    getClusterHeadsV1Route: any = 'api/cluster-heads/v1';
    getExtensionManagersV1Route: any = 'api/extension-managers/v1';
    getExtensionOfficersV1Route: any = 'api/extension-officers/v1';
    getRetailOfficersV1Route: any = 'api/retail-officers-v1';
    getUnimartOutletWiserRoute: any = 'api/dashboard-report-outlet-wise';
    getMeetingDetailsRoute: any = 'api/meeting-activity-farmer-number-image';
    getMeetingActivityStatusRoute: any = 'api/meeting-activity-edit-status';
    getFarmerDetails = 'api/get-farmer-details';
    getProductScanDetail = 'api/farmer-product-scan-details-mdm';
    getWalletPoints = 'api/farmer-wallet-details';
    getFarmerCashBackData = 'farmer-cashback-details';
    getfarmList = '/api/farmers-farm-list/';
    getFarmerCareData = '/api/farmer-care-portal-data/';
    getFarmerOffers = '/api/farmer-applicable-offers';
    uploadReport = '/core/dragonfly/upload';
    farmerBlackListStatus = '/api/farmer-edit-feature-wise-blacklist-status';
    uploadF3ReportRoute = '/api/uploadPartnerUsers';
    getUploadStatusTypeRoute = '/api/bulk-upload-status-type';
    getUploadHistoryRoute = '/api/get-partner-upload-history';
    getUploadHistoryByIdRoute = '/api/get-partner-upload-error-details';
    getPaginatedUploadHistoryByIdRoute = '/api/get-partner-upload-error-details-pagination';


    constructor(@Inject(RESTANGULAR_UNIMART) public restangularUnimart, private restangular: Restangular,
                private _http: HttpClient) {

    }

    /**
     * api/getAllActiveEmployees
     */
    getAllActiveEmployees() {
        return this.restangular.all(this.getAllActiveEmployeesRoute);
    }

    /**
     * api/cluster-heads
     */
    getAllClusterHeadDetail() {
        return this.restangularUnimart.all(this.getClusterHeadRoute);
    }

    /**
     * api/cluster-heads/v1
     */
    getAllClusterHead() {
        return this.restangularUnimart.all(this.getClusterHeadsV1Route);
    }

    /**
     * api/cluster-head/{userId}
     */
    getSingleClusterHead(userId) {
        return this.restangularUnimart.one(this.getClusterHeadRoute, userId);
    }

    /**
     * api/clusterHead/activate
     */
    getSingleClusterHeadActive(userId) {
        return this.restangularUnimart.one(this.getClusterHeadActiveRoute, userId);
    }

    /**
     * api/extension-managers
     */
    getAllExtensionManagersDetail() {
        return this.restangularUnimart.all(this.getExtensionManagersRoute);
    }

    /**
     * api/extension-managers/v1
     */
    getAllExtensionManagers() {
        return this.restangularUnimart.all(this.getExtensionManagersV1Route);
    }

    /**
     * api/extension-manager/{userId}
     */
    getSingleExtensionManager(userId) {
        return this.restangularUnimart.one(this.getExtensionManagersRoute, userId);
    }

    /**
     * api/extensionManager/activate
     */
    getSingleExtensionManagerActive(userId) {
        return this.restangularUnimart.one(this.getExtensionManagerActiveRoute, userId);
    }

    /**
     * api/extension-officers
     */
    getAllExtensionOfficersDetail() {
        return this.restangularUnimart.all(this.getExtensionOfficersRoute);
    }

    /**
     * api/extension-officers/v1
     */
    getAllExtensionOfficers() {
        return this.restangularUnimart.all(this.getExtensionOfficersV1Route);
    }

    /**
     * api/extension-officer/{userId}
     */
    getSingleExtensionOfficer(userId) {
        return this.restangularUnimart.one(this.getExtensionOfficersRoute, userId);
    }

    /**
     * api/extensionOfficer/activate
     */
    getSingleExtensionOfficerActive(userId) {
        return this.restangularUnimart.one(this.getExtensionOfficerActiveRoute, userId);
    }

    /**
     * api/retail-officers
     */
    getRetailOfficersDetail() {
        return this.restangularUnimart.all(this.getRetailOfficersRoute);
    }

    /**
     * api/retail-officers-v1
     */
    getRetailOfficers() {
        return this.restangularUnimart.all(this.getRetailOfficersV1Route);
    }

    /**
     * api/retail-officers/{userId}
     */
    getSingleRetailOfficer(userId) {
        return this.restangularUnimart.one(this.getRetailOfficersRoute, userId);
    }

    /**
     * api/retailerOfficer/activate
     */
    getSingleRetailOfficerActive(userId) {
        return this.restangularUnimart.one(this.getRetailOfficerActiveRoute, userId);
    }

    /**
     * api/date-wise-trips-employeeId
     */
    getAllDateWiseTripsEmployees(serverId) {
        return this.restangular.one(this.getDateWiseTripsRoute, serverId);
    }

    /**
     * api/tripuid-wise-trips
     */
    getAllListOfTrips(tripUid) {
        return this.restangular.one(this.getEmployeeTripsRoute, tripUid);
    }

    /**
     * api/tripuid-wise-trip-meetings
     */
    getALLTripWiseTripMeetings(tripUid) {
        return this.restangular.one(this.getTripWiseTripMeetingsRoute, tripUid);
    }

    /**
     * api/meeting-activity
     */
    getAllUploadedMedias(tripUid) {
        return this.restangular.one(this.getMeetingActivityMediaRoute, tripUid);
    }

    /**
     * api/getEmployeesBasedOnRole
     */
    getEmployeesBasedOnRole(role) {
        return this.restangularUnimart.one(this.getEmployeesBasedOnRoleRoute, role);
    }

    getEmployeesBasedRole(role, searchString, limit, pageNumber) {
        const requestUrl = `${this.getEmployeesBasedOnRoleRoute}/${role}?searchString=${searchString}&limit=${limit}&pageNumber=${pageNumber}`;
        return this.restangularUnimart.one(requestUrl);
    }

    /**
     * api/unimart-outlet/getAll
     */
    getAllUnimartOutlets() {
        return this.restangularUnimart.all(this.getAllOutletRoute);
    }

    /**
     * api/unimart-outlet/getAll
     */
    getUnimartDashboardReport() {
        return this.restangularUnimart.all(this.getUnimartDashboardReportRoute);
    }

    /**
     * api/unimart-outlet/getAll
     */
    getUnimartSalesDashboardReport() {
        return this.restangularUnimart.all(this.getUnimartSalesDashboardReportRoute);
    }

    /**
     * api/unimart-outlet/getAll
     */
    getReportUnimartOutletWise() {
        return this.restangularUnimart.all(this.getUnimartOutletWiseRoute);
    }

    /**
     * api/unimart-outlet/getAllOutlets
     */
    getAllOutletList() {
        return this.restangularUnimart.all(this.getAllUnimartOutletListRoute);
    }

    /**
     * api/unimart-outlet/outlets
     */
    createUnimartOutlet() {
        return this.restangularUnimart.all(this.createUnimartOutletRoute);
    }

    /**
     * api/unimart-outlet/get/byCode/
     */
    getSingleUnimartOutlet(code) {
        return this.restangularUnimart.one(this.getSingleUnimartOutletRoute, code);
    }

    /**
     * api/unimart-outlet/outlet-mdm
     */
    getAllUnimartOutlet() {
        return this.restangularUnimart.all(this.getAllUnimartOutletResourceRoute);
    }

    /**
     * api/farmer-history
     */
    getFarmerMeetingHistory() {
        return this.restangularUnimart.all(this.getFarmerMeetingHistoryRoute);
    }

    /**
     * api/meeting-activity
     */
    getMeetingMedia(meetingId) {
        return this.restangularUnimart.one(this.getMeetingActivityMediaRoute, meetingId);
    }

    /**
     * api/fence-report
     */
    getAllUnimartFenceReport () {
        return this.restangularUnimart.all(this.getUnimartFenceReportRoute);
    }

    /**
     * api/unimart-outlet/unimart-outlet
     */
    getSingleOutlet(outletId) {
        return this.restangularUnimart.one(this.getSingleOutletRoute, outletId);
    }

    /**
     * api/truepos/salesExecutive/getByOutlet
     */
    getTruePosUserByOutlet() {
        return this.restangularUnimart.one(this.getSalesExecutiveByOutletRoute);
    }

    /**
     * api/truepos/salesExecutive/sync
     */
    getSyncTruePosSalesExecutive() {
        return this.restangularUnimart.one(this.getSyncSaleExecutivesRoute);
    }

    /**
     * api/getEmployees
     */
    getListOfChildUser() {
        return this.restangularUnimart.one(this.getChildUsersRoute);
    }

    /**
     * api/unimart-outlet/outlet-mapped-employee
     */
    getOutletMappedUser() {
        return this.restangularUnimart.all(this.getOutletMappedUserRoute);
    }

    /**
     * api/date-wise-trips-employeeId-v2
     */
    getTripDetailsOutletWise() {
        return this.restangularUnimart.all(this.getTripDetailsBasedOnOutletRoute);
    }

    /**
     * api/dashboard-report-outlet-wise-drill-down
     */
    getReportOutletWiseDrillDown() {
        return this.restangularUnimart.one(this.getDashboardReportOutletWiseRoute);
    }

    /**
     * api/dashboard-report-outlet-wise
     */
    getOutletWiseReport() {
        return this.restangularUnimart.one(this.getUnimartOutletWiserRoute);
    }

    /**
     * api/meeting-activity-farmer-number-image
     */
    getAllMeetings() {
        return this.restangularUnimart.all(this.getMeetingDetailsRoute);
    }

    /**
     * api/meeting-activity-farmer-number-image
     */
    getFarmerMeetingMedia(meetingId) {
        return this.restangularUnimart.one(this.getMeetingDetailsRoute, meetingId);
    }

    /**
     * api/meeting-activity-edit-status
     */
    getMeetingActivityStatus() {
        return this.restangularUnimart.all(this.getMeetingActivityStatusRoute);
    }

    /**
     * api/get-farmer-details
     */
    getFarmerData() {
        return this.restangularUnimart.all(this.getFarmerDetails);
    }

    /**
     * api/farmer-product-scan-details
     */
    getProductScanHistory() {
        return this.restangularUnimart.all(this.getProductScanDetail);
    }

    /**
     * api/farmer-wallet-details
     */

    getFarmerWalletDetails() {
        return this.restangularUnimart.all(this.getWalletPoints);
    }

    getFarmerCashBackDetails(cashBackDto) {
        const httpOptions = {
            headers: new HttpHeaders({
                clientId: environment.farmerAppClientKey,
                clientSecret: environment.farmerAppClientSecret,
                Authorization: `Bearer ${AuthenticationHelper.getToken()}`
            })
        };
        return this._http.post( environment.farmerAppBaseURL + this.getFarmerCashBackData, cashBackDto, httpOptions);
    }

    /**
     * '/api/farmers-farm-list/'
     */

    getFarmStatus(farmerID) {
        return this.restangularUnimart.one(this.getfarmList , farmerID);
    }

    /**
     * '/api/farmer-care-portal-data/'
     */
    getFarmerCareDetails(farmerID) {
        return this.restangularUnimart.one(this.getFarmerCareData , farmerID);
    }
    /**
     * '/api/farmer-applicable-offers/'
     */
    getFarmerOffersDetais(farmerId, pageNumber, limit) {
        const invalidateURL = `${this.getFarmerOffers}?farmerId=${farmerId}&pageNumber=${pageNumber}&limit=${limit}`;
        return this.restangularUnimart.all(invalidateURL);
    }
    getUpload() {
        return this.restangularUnimart.all(this.uploadReport);
    }

    /**
     * '/api/farmer-edit-feature-wise-blacklist-status'
     */
    updateFarmerBlacklistStatus(farmerId, featureName, featureStatus) {
        const farmerblackListStatus = `${this.farmerBlackListStatus}?farmerId=${farmerId}&featureName=${featureName}&featureStatus=${featureStatus}`;
        return this.restangularUnimart.all(farmerblackListStatus);
    }

    /**
     *'/api/uploadPartnerUsers';
     */
    uploadF3Detail(status) {
        const url = `${this.uploadF3ReportRoute}?type=${status}`;
        return this.restangularUnimart.all(url);
    }

    getUploadStatusType() {
        return this.restangularUnimart.one(this.getUploadStatusTypeRoute);
    }

    getUploadHistoryList() {
        return this.restangularUnimart.all(this.getUploadHistoryRoute);
    }

    getUploadHistoryById(id) {
        return this.restangularUnimart.one(this.getUploadHistoryByIdRoute, id);
    }

    getPaginatedUploadHistoryById() {
        return this.restangularUnimart.all(this.getPaginatedUploadHistoryByIdRoute);
    }
}
