import {Injectable} from '@angular/core';
import {Restangular} from 'ngx-restangular';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../environments/environment";
import { AuthenticationHelper } from 'app/helpers/authentication';

@Injectable()
export class MachineOperatorMappingService {
    baseMachinesOperatorRoute: any = 'api/machine-operator-mappings';
    baseMachinesNotMappedRoute: any = 'api/machines-not-mapped';
    baseOperators: any = 'api/operators';
    baseMachinesNotMappedToOperatorRoute: any = 'api/machines-not-mapped-v1';
    baseMachineOperatorMappingByGeographyRoute: any = 'api/machine-operator-mappings/getActiveMappingsByGeography';
    getMachineListRoute: any = 'api/machines-pageable';
    baseURL: string = environment.baseUrl;
    constructor(private restangular: Restangular, private http: HttpClient) {

    }

    /**
     * To create custom header by passing NF-Service-Area-Id
     */
    createHeaderWithServiceAreaId() {
        return (new HttpHeaders({
            Authorization: `Bearer ${AuthenticationHelper.getToken()}`,
            'NF-Service-Area-Id': localStorage.getItem('serviceAreaId')
        }));
    }

    /**
     * To create custom header by passing NF-Service-Area-Id
     */
    createHeaderWithoutServiceAreaId() {
        return (new HttpHeaders({
            Authorization: `Bearer ${AuthenticationHelper.getToken()}`
        }));
    }

    /**
     * api/machine-operator-mappings
     */
    getAllMappings(mappingData) {
        return this.http.post(this.baseURL + this.baseMachinesOperatorRoute, mappingData, {
            headers: localStorage.getItem('serviceAreaId')
                ? this.createHeaderWithServiceAreaId() : this.createHeaderWithoutServiceAreaId()
        });
    }

    /**
    * api/operators/{id}/machine-operator-mappings
    */
    getSingleMapping(id) {
        let machineMapping = '';
        machineMapping = '/machine-operator-mappings';
        return this.http.get(this.baseURL + this.baseOperators + '/' + id + machineMapping, {
            headers: localStorage.getItem('serviceAreaId')
                ? this.createHeaderWithServiceAreaId() : this.createHeaderWithoutServiceAreaId()
        });
    }

    /**
     * api/machines-not-mapped
     */
    getAllUnMappedMachines() {
        return this.restangular.all(this.baseMachinesNotMappedRoute);
    }

    /**
     * api/machine-operator-mappings/{{id}}
     */
    getUnAssignMapping(id) {
        let effective = '';
        effective = '?effectiveToMillis=0';
        return this.http.delete(this.baseURL + this.baseMachinesOperatorRoute + '/' + id + effective, {
            headers: localStorage.getItem('serviceAreaId')
                ? this.createHeaderWithServiceAreaId() : this.createHeaderWithoutServiceAreaId()
        });
    }

    /**
     * api/machines-not-mapped
     */
    getAllMachinesNotMappedToOperator () {
        return this.restangular.all(this.baseMachinesNotMappedToOperatorRoute);
    }

    /**
     * api/machine-operator-mappings/getActiveMappingsByGeography
     * get all machine-operator by booking geography.
     */
    getMachinrOperatorMappingByGeography() {
        return this.restangular.all(this.baseMachineOperatorMappingByGeographyRoute);
    }

    getMachineList(searchString, limit, pageNumber) {
        let url = `${this.getMachineListRoute}?searchString=${searchString}&limit=${limit}&pageNumber=${pageNumber}`;
        return this.restangular.one(url);
    }

    publishVedioApiRequest(payload) {
        let url = `${this.baseURL}api/video-feed/add-content-metadata`;
        return this.http.post(url , payload, {
            headers: localStorage.getItem('serviceAreaId')
                ? this.createHeaderWithServiceAreaId() : this.createHeaderWithoutServiceAreaId()
        });
    }

}
