import { Injectable, inject } from "@angular/core";
import { AESEncryptDecryptService } from "app/app-services/AESEncryptDecrypt-service";
import { PAGES_MENU } from "app/pages/pages.menu";
@Injectable()
export class AuthenticationHelper {

    private constructor() {

    }

    /**
     * To set user token in localStorage
     * @param token
     */

    static setToken(token) {
        const encryptDecryptService = new AESEncryptDecryptService();
        const encriptedToken = encryptDecryptService.encrypt(token);
        localStorage.setItem('token', encriptedToken);
    }

    static setRefreshToken(token) {
        const encryptDecryptService = new AESEncryptDecryptService();
        const encriptedRefreshTokenToken = encryptDecryptService.encrypt(token);
        localStorage.setItem('refresh_token', encriptedRefreshTokenToken);
    }

    /**
     * To return token from localStorage
     * @returns {string|null}
     */
    static getToken() {
        const encryptDecryptService = new AESEncryptDecryptService();
        return localStorage.getItem('token') ? encryptDecryptService.decrypt(localStorage.getItem('token')) : "";
    }


    static getRefreshToken() {
        const encryptDecryptService = new AESEncryptDecryptService();
        return localStorage.getItem('refresh_token') ? encryptDecryptService.decrypt(localStorage.getItem('refresh_token')): "";
    }

    /**
     * To return token from localStorage
     * @returns {string|null}
     */
    static getUsername() {
        return localStorage.getItem('username');
    }

    /**
     * To set the logged In User
     * @param userData
     */
    static setLoggedInUser(userData) {
        localStorage.setItem('loggedInUser', JSON.stringify(userData));
    }

    /**
     * To return the Logged In User
     */
    static getLoggedInUser() {
        return JSON.parse(localStorage.getItem('loggedInUser'));
    }

    /**
     * To get user token in localStorage
     */
    static setUsername(username) {
        localStorage.setItem('username', username);
    }

    /**
     * To set logged in user in localStorage
     */
    static setUser(user) {
        localStorage.setItem('userData', JSON.stringify(user));
    }

    /**
     * To get logged in user in localStorage
     */
    static getLoggedInUserDetails() {
        return JSON.parse(localStorage.getItem('userData'));
    }

    /**
     * To check the token and return if user is logged in or not
     * @returns {boolean}
     */
    static isLoggedIn() {
        let token = this.getToken();
        if (token && token.length > 0) {
            return true;
        }
        return false;
    }

    static clearLocalStorage() {
        localStorage.clear();
    }

    static checkUserHasAnyMDMAuthourityOrNot(responseRole) {
        let MDMAuthourit = false
        PAGES_MENU.forEach(element1 => {
            responseRole.forEach(element2 => {
                if(element1.key == element2.authority) {
                    MDMAuthourit = true;
                }
            });
            if(MDMAuthourit) {
                return true;
            }
        });
        if(MDMAuthourit) {
            return true;
        } else {
            return false;
        }
    }
}
