import { Injectable, EventEmitter, Output, Directive } from '@angular/core';

@Directive()
@Injectable()
export class GlobalEvents {
    @Output() changeContentTopText: EventEmitter<any> = new EventEmitter(true);
    isFullScreenMap:boolean = false;
    activeMenu:string = 'unimart';

    constructor() {

    }

    /**
     * to set the content top title
     * @param value
     */
    setChangedContentTopText(value) {
        this.changeContentTopText.emit(value);
    }

    /**
     * return the event for value changes of content top title
     * @returns {EventEmitter<any>}
     */
    getChangedContentTopText(): EventEmitter<any> {
        return this.changeContentTopText;
    }
}