import {Injectable} from '@angular/core';
import {Restangular} from 'ngx-restangular';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../environments/environment";
import { AuthenticationHelper } from 'app/helpers/authentication';

@Injectable()
export class MachineService {
    baseMachinesRoute: any = 'api/machines';
    baseMachineDataRoute: any = 'api/machine-data';
    baseMachinesTypesRoute: any = 'api/machineTypes';
    baseMachineTrackerRoute: any = 'api/machinetracker-v1';
    getAllMachinesByStateRoute: any = 'api/machines/getByState';
    getAllMachinesWithLatestLocationRoute: any = 'api/machines-with-latest-location-v1';
    getAllMachineBreakDownRequestsRoute: any = 'api/machine-breakdown-report';
    getAllMachineMaintenanceRequestLocationRoute: any = 'api/machine-maintenance-request-location';
    getAllMachineMaintenanceStatusSummaryRoute: any = 'api/status-wise-count';
    getSingleMachineBreakdownRequestRoute: any = 'api/view-machine-wise-breakdown';
    getPageableMachineRoute: any = 'api/machines-pageable-mdm';
    getAllInspectionQuestionsRoute: any = 'api/inspection-question-masters';
    createMachineHandOverRoute: any = 'api/machine-hand-over-details';
    getMachineHandOverListRoute: any = 'api/machine-hand-over-details-mdm';
    getSingleMachineHandOverRoute: any = 'api/machine-hand-over-details';
    getStateWiseCountRoute: any = 'api/state-wise-count';
    getSingleStateWiseStatusRoute: any = 'api/state-status-wise-count';
    getNetworkServiceProviderRoute: any = 'api/network-providers';
    getControllerReportRoute: any = 'api/heart-beat-summary';
    baseMachineBLEDeviceMappingRoute: any = 'api/machine-ble-device-mappings';
    baseBLEDDeviceListRoute: any = 'api/ble-device-details-pagination';
    uploadControllerInBulkRoute: any = 'api/devices-onboard';
    baseMachineBLEDeviceMappingPageableRoute = 'api/machines-ble-device-mapping-pageable';
    baseAllActiveMachinesByTypeRoute: any = 'api/machines/getActiveByType';
    getAllBleDeviceDetailsRoute: any = 'api/ble-device-details-pagination';
    getSingleControllerRoute: any = 'api/ble-device-deletion';
    getBleDetailsRoute: any = 'api/ble-device-details';
    deleteSingleControllerRoute: any = 'api/ble-device-deletion-v1';
    getMachineMaintenanceQuotationRoute: any = 'api/machine-maintenance-quotation/list';
    getSingleMachineMaintenanceQuotationRoute: any = 'api/machine-maintenance-quotation';
    getSoilMoistureDataRoute: any = 'api/lora-soil-moisture';
    getLoraSMDeviceMappingRoute: any = 'api/lora-sm-device-farmer-mapping';
    getSelfieWithMachineRoute: any = 'api/selfie-with-machine/list';
    getLiveSprayingMachineRoute: any = 'api/iot-controller/live-spraying-machines';
    getLiveSprayingMachinePatternRoute: any = 'api/iot-controller/live-spraying-pattern';
    getListOfMachineSparePartsRoute: any = 'api/spare-part/list';
    getMachineSparePartRoute: any = 'api/spare-parts';
    createUpdateMachineSpareRote: any = 'api/spare-parts-mdm';
    getControllerDiagnosticRoute: any = 'api/get-controller-diagnosis-report';
    removeControllerMappingRoute: any = 'api/machine-ble-device-mappings';
    machineBulkUploadRoute: any = 'api/uploadMachines';
    getServiceList: any = 'api/booking-config/all-services';
    uploadSparetRoute = '/api/upload-spare-parts';
    machineBillingStatusRoute = 'api/machine-skills/';
    changemachineBillingStatusRoute = 'api/system-billing-machine-map/';
    uploadDailyQuizRoute = 'api/user-feed/questions-from-csv';
    getAllDailyQuizListRoute = 'api/user-feed/get-questions';
    createDailyQuizListRoute = '/api/user-feed/questions';
    getDailyQuizListRoute = 'api/user-feed/get-questions-with-translations';
    getListOfVendorsRoute = 'api/vendor/search';
    createUpdateDeleteVendorRoute = 'api/vendor';
    uploadVendorRoute = 'api/vendor/spare-part/upload';
    getListOfVendorSparePartsRoute = 'api/vendor/search-spare-part';
    createUpdateDeleteVendorSparePartRoute = 'api/vendor/spare-part';
    getListOfSingleVendorSparePartRoute = 'api/vendor/vendor-spare-part/search';

    baseURL: string = environment.baseUrl;
    constructor(private restangular: Restangular,
        private http: HttpClient) {
    }

    createHeader() {
        return (new HttpHeaders({
            Authorization: `Bearer ${AuthenticationHelper.getToken()}`,
        }));
    }

    /**
     * api/machines
     */
    getAllMachines() {
        return this.restangular.all(this.baseMachinesRoute);
    }

    /**
     * api/machines/{machineId}
     */
    getSingleMachine(machineId) {
        return this.restangular.one(this.baseMachinesRoute, machineId);
    }

    /**
     * api/machineTypes
     */
    getAllMachineTypes() {
        return this.restangular.all(this.baseMachinesTypesRoute);
    }

    /**
     * api/machinetracker
     */
    fetchMachineTrackData() {
        return this.restangular.all(this.baseMachineTrackerRoute);
    }

    /**
     * api/machines/getActiveByType
     */
    getAllActiveMachinesByType() {
        return this.restangular.all(this.baseMachinesRoute).all('getActiveByType');
    }

    /**
     * api/machines/getByState
     */
    getAllMachinesByState() {
        return this.restangular.one(this.baseMachinesRoute).one('getByState');
    }

    /**
     * api/machines-with-latest-location
     */
    getAllMachinesWithLatestLocation() {
        return this.restangular.all(this.getAllMachinesWithLatestLocationRoute);
    }

    /**
     * api/machine-data/{machineId}
     */
    getSingleMachineBreakdwon(machineId) {
        return this.restangular.one(this.baseMachineDataRoute, machineId);
    }

    /**
     * api/machine-breakdown-report
     */
    getAllMachineBreakDownRequests() {
        return this.restangular.all(this.getAllMachineBreakDownRequestsRoute);
    }

    /**
     * api/machine-maintenance-request-location
     */
    getAllMachineMaintenanceRequests() {
        return this.restangular.all(this.getAllMachineMaintenanceRequestLocationRoute);
    }

    /**
     * api/machine-maintenance-status-summary
     */
    getAllMachineMaintenanceStatusSummary() {
        return this.restangular.all(this.getAllMachineMaintenanceStatusSummaryRoute);
    }

    /**
     * api/view-machine-wise-breakdown
     */
    getSingleMachineBreakdownRequests(machineNumber) {
        return this.restangular.one(this.getSingleMachineBreakdownRequestRoute, machineNumber);
    }

    /**
     * api/machines-pageable-mdm
     */
    getPageableMachines() {
        return this.restangular.one(this.getPageableMachineRoute);
    }

    /**
     * api/inspection-question-masters
     */
    getAllInspectionQuestions() {
        return this.restangular.all(this.getAllInspectionQuestionsRoute);
    }

    /**
     * api/machine-hand-over-details
     */
    createMachineHandOver() {
        return this.restangular.all(this.createMachineHandOverRoute);
    }

    /**
     * api/machine-hand-over-details-mdm
     */
    getAllMachineHandOverDetails() {
        return this.restangular.all(this.getMachineHandOverListRoute);
    }

    /**
     * api/machine-hand-over-details
     */
    getSingleMachineHandOverDetails(handOverId) {
        return this.restangular.one(this.getSingleMachineHandOverRoute, handOverId);
    }

    /**
     * api/state-wise-count
     */
    getAllStateWiseCount() {
        return this.restangular.one(this.getStateWiseCountRoute);
    }

    /**
     * api/state-status-wise-count
     */
    getSingleStateWiseMachineStatus() {
        return this.restangular.one(this.getSingleStateWiseStatusRoute);
    }

    /**
     * api/network-providers
     */
    getNetworkProvider() {
        return this.restangular.one(this.getNetworkServiceProviderRoute);
    }

    /**
     * api/heart-beat-summary
     */
    getControllerReports() {
        return this.restangular.one(this.getControllerReportRoute);
    }

    /**
     * api/machine-ble-device-mappings
     */
    addMachineBLEMapping() {
        return this.restangular.all(this.baseMachineBLEDeviceMappingRoute);
    }

    /**
     * api/ble-device-details-pagination
     */
    getALLBLEDeviceList() {
        return this.restangular.all(this.baseBLEDDeviceListRoute);
    }

    /**
     * api/machines-ble-device-mapping-pageable
     */
    getALLBLEMappedDeviceList() {
        return this.restangular.all(this.baseMachineBLEDeviceMappingPageableRoute);
    }

    /**
     * api/machines/getActiveByType
     */
    getAllActiveMachinesByID() {
        return this.restangular.all(this.baseAllActiveMachinesByTypeRoute);
    }

    /**
     * api/ble-device-details-pagination
     */
    getAllBleDeviceDetails() {
        return this.restangular.all(this.getAllBleDeviceDetailsRoute);
    }

    /**
     * api/ble-device-deletion
     */
    deleteSingleController(controllerId) {
        return this.restangular.one(this.deleteSingleControllerRoute, controllerId);
    }

    /**
     * api/ble-device-details
     */
    addBleDevice() {
        return this.restangular.all(this.getBleDetailsRoute);
    }

    /**
     * api/ble-device-details
     */
    getSingleControllerDetails(controllerId) {
        return this.restangular.one(this.getBleDetailsRoute, controllerId);
    }

    /**
     * api/machine-maintenance-quotation/list
     */
    getMachineMaintenanceQuotationDetail() {
        return this.restangular.all(this.getMachineMaintenanceQuotationRoute);
    }

    /**
     * afs/api/machine-maintenance-quotation
     * @param quotationId
     */
    getSingleMachineMaintenanceQuotation(quotationId) {
        return this.restangular.one(this.getSingleMachineMaintenanceQuotationRoute, quotationId);
    }

    /**
     * api/lora-soil-moisture
     */
    getSoilMoistureData() {
        return this.restangular.one(this.getSoilMoistureDataRoute);
    }

    /**
     * api/lora-sm-device-farmer-mapping
     */
    getDeviceFarmerMapping() {
        return this.restangular.one(this.getLoraSMDeviceMappingRoute);
    }

    /**
     * api/selfie-with-machine/list
     */
    getSelfieWithMachine() {
        return this.restangular.all(this.getSelfieWithMachineRoute);
    }

    /**
     * api/iot-controller/live-spraying-machines
     */
    getLiveSprayingMachine() {
        return this.restangular.all(this.getLiveSprayingMachineRoute);
    }

    /**
     * api/iot-controller/live-spraying-pattern
     * @param machineNumber
     */
    getLiveSPrayingPattern(machineNumber) {
        return this.restangular.one(this.getLiveSprayingMachinePatternRoute, machineNumber);
    }

    /**
     * api/spare-part/list
     */
    getAllSparePartList() {
        return this.restangular.all(this.getListOfMachineSparePartsRoute);
    }

    /**
     * api/spare-parts/{id}
     * @param sparePartId
     */
    getSingleSparePart(sparePartId) {
        return this.restangular.one(this.getMachineSparePartRoute).one(sparePartId);
    }

    createUpdateSparePart() {
        return this.restangular.all(this.createUpdateMachineSpareRote);
    }

    deleteSelectedSparePart(sparePartId) {
        return this.restangular.one(this.getMachineSparePartRoute, sparePartId);
    }

    getControllerDiagnostic(referenceId) {
        return this.restangular.one(this.getControllerDiagnosticRoute + '?controllerReferenceId=' + referenceId);
    }

    uploadControllerInBulk() {
        return this.restangular.all(this.uploadControllerInBulkRoute);
    }

    removeSelectedControllerMapping(mappingDTO) {
        return this.http.delete(this.baseURL + this.removeControllerMappingRoute
            + '/' + mappingDTO.machineId + '/' + mappingDTO.bleDeviceDetailId, { headers: this.createHeader() });
    }

    uploadMachines() {
        return this.restangular.all(this.machineBulkUploadRoute);
    }

    /**
     *'api/booking-config/all-booking-groups';
     */

    getServiceTypeList() {
        return this.restangular.all(this.getServiceList);
    }

    uploadSparePartDetail() {
        return this.restangular.all(this.uploadSparetRoute);
    }

    getMachineBillingStatus(id) {
        return this.restangular.one(this.machineBillingStatusRoute, id);
    }

    changeMachineBillingStatus(machineId, id) {
        return this.restangular.all(this.changemachineBillingStatusRoute).one(machineId).one(id);
    }

    uploadDailyQuizDetail() {
        return this.restangular.all(this.uploadDailyQuizRoute);
    }

    getAllDailyQuizDetailList() {
        return this.restangular.all(this.getAllDailyQuizListRoute);
    }

    addDailyQuizDetails() {
        return this.restangular.all(this.createDailyQuizListRoute);
    }

    getDailyQuizDetailList() {
        return this.restangular.all(this.getDailyQuizListRoute);
    }

    getAllVendorsList() {
        return this.restangular.all(this.getListOfVendorsRoute);
    }

    getSingleVendor(vendorId) {
        return this.restangular.one(this.getListOfVendorsRoute).one(vendorId);
    }

    createUpdateDeleteVendor() {
        return this.restangular.all(this.createUpdateDeleteVendorRoute);
    }

    uploadVendorDetail() {
        return this.restangular.all(this.uploadVendorRoute);
    }

    getAllVendorSparePartList() {
        return this.restangular.all(this.getListOfVendorSparePartsRoute);
    }

    createUpdateDeleteVendorSparePart() {
        return this.restangular.all(this.createUpdateDeleteVendorSparePartRoute);
    }

    getSingleVendorSparePart(sparePartCode, vendorId) {
        return this.restangular.one(this.getListOfSingleVendorSparePartRoute).one(sparePartCode, vendorId);
    }

    getStateBudgetDetails(stateId) {
        let url = `${this.baseURL}api/machine-maintenance-quotation/stateBaseBudget/${stateId}`;
        return this.http.get(url ,{
            headers: this.createHeader() 
        });
    }

    getStateBudgetTableDetails(stateId, page) {
        let url = `${this.baseURL}api/machine-maintenance-quotation/stateBaseBudget/history/${stateId}?page=${page}&limit=10`;
        return this.http.get(url ,{
            headers: this.createHeader() 
        });
    }

    saveStateBudgetDetails(payload) {
        let url = `${this.baseURL}api/machine-maintenance-quotation/stateBaseBudget`;
        return this.http.post(url , payload,{
            headers: this.createHeader() 
        });
    }
}
