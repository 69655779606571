import {Component, OnInit} from '@angular/core';
import {GlobalState} from '../../../helpers/global.state';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {AppConstant, UserRoleConstants} from "../../../constants/app.constant";
import {UtilityHelper} from "../../../helpers/utility";
import * as _ from "lodash";
import { MsalServiceService } from 'app/helpers/msal-service.service';

@Component({
    selector: 'ba-page-top',
    templateUrl: './baPageTop.html',
    styleUrls: ['./baPageTop.scss'],
})

export class BaPageTop extends AppConstant implements OnInit {
    public isScrolled: boolean = false;
    public isMenuCollapsed: boolean = false;
    public userName: string;
    public userProfileImage: string;
    public loggedInUser: any;
    public isBOD: boolean;
    public isAuthenticated: boolean;

    constructor(public toastr: ToastrService, private _state: GlobalState, public router: Router,
                public utility: UtilityHelper,private newAuthService: MsalServiceService,) {
        super();

        this._state.subscribe('menu.isCollapsed', (isCollapsed) => {
            this.isMenuCollapsed = isCollapsed;
        });

        this.userName = localStorage.getItem('username') || 'Admin';
        this.loggedInUser = JSON.parse(localStorage.getItem('loggedInUser'));

        if (this.loggedInUser) {
            this.isBOD = _.some(this.loggedInUser.authorities, { 'serverId': UserRoleConstants.BDO });
        }

        if (this.userName === 'sweta.sipuram@uniphos.com') {
            this.userName = 'Sweta';
        }
    }

    async ngOnInit() {
        this.isAuthenticated = await this.newAuthService.isAuthenticated();
    }

    /**
     * To toggle the left sidebar component
     * @returns {boolean}
     */
    public toggleMenu() {
        this.isMenuCollapsed = !this.isMenuCollapsed;
        this._state.notifyDataChanged('menu.isCollapsed', this.isMenuCollapsed);
        return false;
    }

    /**
     * Called on change of sidebar component
     */
    public scrolledChanged(isScrolled) {
        this.isScrolled = isScrolled;
    }

    /**
     * To logout the user
     */
    public async loggedOff() {
        localStorage.clear();
        await this.newAuthService.logout();
        this.toastr.success('Logged Out successfully');
    }

    /**
     * To navigate the user to profile
     */
    public navigateToProfile() {
        this.router.navigate(['profile']);
    }

    /**
     * To navigate the user to dashboard
     */
    public navigateToDashboard() {
        this.router.navigate(['dashboard']);
    }

    /**
     * To navigate the logged in BDO Profile
     */
    public navigateToUserProfile() {
        this.router.navigate(['/users/edit-user', this.loggedInUser.profile.serverId],
            { queryParams: { user: 1, profile: true }});
    }
}
