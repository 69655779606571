import { Injectable } from '@angular/core';
import { Restangular } from 'ngx-restangular';

@Injectable()
export class NoticeBoardService {

    baseAddTargetRoute: any = 'api/target';
    baseAddAnnouncementRoute: any = 'api/announcement';
    baseAllNoticeBoardTargetRoute: any = 'api/target-paginated';
    baseAllTopAnnouncementRoute: any = 'api/top-announcements-paginated';
    baseGetAllLikesForAnnouncementRoute: any = 'api/likes';
    baseGetAllCommentsForAnnouncementRoute: any = 'api/comments';

    constructor(private restangular: Restangular) {

    }

    /**
     * api/target
     */
    addTarget() {
        return this.restangular.all(this.baseAddTargetRoute);
    }

    /**
     * api/announcement
     */
    addAnnouncement() {
        return this.restangular.all(this.baseAddAnnouncementRoute);
    }

    /**
     * api/get-target
     */
    getAllTargetList() {
        return this.restangular.all(this.baseAllNoticeBoardTargetRoute);
    }

    /**
     * api/top-announcements-paginated
     */
    getAllAnnouncementList() {
        return this.restangular.all(this.baseAllTopAnnouncementRoute);
    }

    /**
     * api/likes
     */
    getAllLikesForAnnouncement(announcementId) {
        return this.restangular.one(this.baseGetAllLikesForAnnouncementRoute, announcementId);
    }

    /**
     * api/comments
     */
    getAllCommentsForAnnouncement(announcementId) {
        return this.restangular.one(this.baseGetAllCommentsForAnnouncementRoute, announcementId);
    }
}