import { Routes, RouterModule } from "@angular/router";
import { Pages } from "./pages.component";
import { NgModule } from "@angular/core";
import { CallbackComponent } from "../callback.component";
import { AuthorizationGuard, PointCreditGuard } from "../guards/app.guard";
import { MsalAuthGuard } from "app/helpers/msal-auth.guard";

export const routes: Routes = [
  {
    path: "",
    loadChildren: () =>
      import("./home/home.module").then((m) => m.HomeComponentModule),
  },
  {
    path: "callback",
    component: CallbackComponent,
  },
  {
    path: "home",
    loadChildren: () =>
      import("./home/home.module").then((m) => m.HomeComponentModule),
  },
  {
    path: "login",
    loadChildren: () =>
      import("./home/home.module").then((m) => m.HomeComponentModule),
  },
  {
    path: "login/:tab",
    loadChildren: () =>
      import("./home/home.module").then((m) => m.HomeComponentModule),
  },
  {
    path: "forgot-password",
    loadChildren: () =>
      import("../pages/forgot-password/forgot-password.module").then(
        (m) => m.ForgotPasswordModule
      ),
  },
  {
    path: "reset-password",
    loadChildren: () =>
      import("../pages/reset-password/reset-password.module").then(
        (m) => m.ResetPasswordModule
      ),
  },
  {
    path: "farmer/:id",
    loadChildren: () =>
      import("../pages/farmer/farmer.module").then(
        (m) => m.FarmerComponentModule
      ),
  },
  {
    path: "Crm-dashboard",
    loadChildren: () =>
      import("./crm-dashboard/crm-dashboard.module").then(
        (m) => m.CrmDashboardModule
      ),
  },
  {
    path: "",
    component: Pages,
    children: [
      {
        path: "",
        redirectTo: "dashboard",
        pathMatch: "full",
      },
      {
        path: "dashboard",
        loadChildren: () =>
          import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
      },
      {
        path: "bookings",
        data: {
          key: "view_bookings_tab",
        },
        loadChildren: () =>
          import("./bookings/bookings.module").then(
            (m) => m.BookingsComponentModule
          ),
        canActivate: [AuthorizationGuard],
      },
      {
        path: "users",
        data: {
          key: "view_afs_users_tab",
        },

        loadChildren: () =>
          import("./users/users.module").then((m) => m.UsersModule),
        canActivate: [AuthorizationGuard],
      },
      {
        path: "users/:tab",
        data: {
          key: "view_afs_users_tab",
        },
        loadChildren: () =>
          import("./users/users.module").then((m) => m.UsersModule),
        canActivate: [AuthorizationGuard],
      },
      {
        path: "machines",
        data: {
          key: "view_machine_tab",
        },
        loadChildren: () =>
          import("./machines/machines.module").then(
            (m) => m.MachinesComponentModule
          ),
        canActivate: [AuthorizationGuard],
      },
      {
        path: "machines/:id",
        data: {
          key: "view_machine_tab",
        },
        loadChildren: () =>
          import("./machines/machines.module").then(
            (m) => m.MachinesComponentModule
          ),
        canActivate: [AuthorizationGuard],
      },
      {
        path: "controllers",
        data: {
          key: "view_machine_tab",
        },
        loadChildren: () =>
          import("./controller/controller.module").then(
            (m) => m.ControllerComponentModule
          ),
        canActivate: [AuthorizationGuard],
      },
      {
        path: "reports",
        data: {
          key: "view_reports_tab",
        },
        loadChildren: () =>
          import("./reports/reports.module").then(
            (m) => m.ReportsComponentModule
          ),
        canActivate: [AuthorizationGuard],
      },
      {
        path: "Communication-Info",
        data: {
          key: "view_communication_info_tab",
        },
        loadChildren: () =>
          import("./sms-info/sms-info.module").then((m) => m.SmsInfoModule),
        canActivate: [AuthorizationGuard],
      },
      {
        path: "picture-verification",
        data: {
          key: "ROLE_PICTURE_VERIFICATION",
        },
        loadChildren: () =>
          import("./picture-verification/picture-verification.module").then(
            (m) => m.PictureVerificationModule
          ),
        canActivate: [AuthorizationGuard],
      },
      {
        path: "product-sales",
        data: {
          key: "view_bookings_tab",
        },
        loadChildren: () =>
          import("./kishan-shop/kishan-shop.module").then(
            (m) => m.KishanShopModule
          ),
        canActivate: [AuthorizationGuard],
      },
      {
        path: "all-polls-details",
        data: {
          key: "view_poll_and_quizzes_tab",
        },
        loadChildren: () =>
          import("./polls-ui/all-polls-details/all-polls-details.module").then(
            (m) => m.allPollsDetailsModule
          ),
        canActivate: [AuthorizationGuard],
      },
      {
        path: "create-polls",
        data: {
          key: "view_poll_and_quizzes_tab",
        },
        loadChildren: () =>
          import("./polls-ui//polls-form/polls-form.module").then(
            (m) => m.pollsFormModule
          ),
        canActivate: [AuthorizationGuard],
      },
      {
        path: "video-verification",
        data: {
          key: "role_view_video_tab",
        },
        loadChildren: () =>
          import("./video-verification/video-verification.module").then(
            (m) => m.VideoVerificationModule
          ),
        canActivate: [AuthorizationGuard],
      },
      {
        path: "geographies",
        data: {
          key: "view_geography",
        },
        loadChildren: () =>
          import("./geographies/geographies.module").then(
            (m) => m.GeographiesComponentModule
          ),
        canActivate: [AuthorizationGuard],
      },
      {
        path: "geographies/:tab",
        data: {
          key: "view_geography",
        },
        loadChildren: () =>
          import("./geographies/geographies.module").then(
            (m) => m.GeographiesComponentModule
          ),
        canActivate: [AuthorizationGuard],
      },
      {
        path: "profile",
        loadChildren: () =>
          import("./user-profile/profile.module").then((m) => m.ProfileModule),
        canActivate: [AuthorizationGuard],
      },
      {
        path: "help",
        data: {
          key: "view_help_tab",
        },
        loadChildren: () =>
          import("./help/help.module").then((m) => m.HelpComponentModule),
        canActivate: [AuthorizationGuard],
      },
      {
        path: "points-management",
        data: {
          key: "view_points_management_tab",
        },
        loadChildren: () =>
          import("./points-management/points-management.module").then(
            (m) => m.PointsManagementModule
          ),
        canActivate: [AuthorizationGuard],
      },
      {
        path: "Upload-insurance-details",
        data: {
          key: "view_insurance_upload",
        },
        loadChildren: () =>
          import("./upload-insurance-details/upload-insurance-details.module").then(
            (m) => m.UploadInsuranceDetailsModule
          ),
        canActivate: [AuthorizationGuard],
      },
      {
        path: "f3-data-upload",
        data: {
          key: "view_partner_details_tab",
        },
        loadChildren: () =>
          import("./f3-report-upload/f3-report-upload.module").then(
            (m) => m.f3ReportUploadModule
          ),
        canActivate: [AuthorizationGuard],
      },
      {
        path: "help-desk",
        data: {
          key: "view_help_desk_tab",
        },
        loadChildren: () =>
          import("./help/help.module").then((m) => m.HelpComponentModule),
        canActivate: [AuthorizationGuard],
      },
      {
        path: "farmers",
        data: {
          key: "view_farmer_tab",
        },
        loadChildren: () =>
          import("./farmer-main/farmer-main.module").then(
            (m) => m.FarmerMainComponentModule
          ),
        canActivate: [AuthorizationGuard],
      },
      {
        path: "bookings",
        data: {
          key: "view_bookings_tab",
        },
        loadChildren: () =>
          import("./unassigned-bookings/unassigned-bookings.module").then(
            (m) => m.UnAssignedBookingsComponentModule
          ),
        canActivate: [AuthorizationGuard],
      },
      {
        path: "back-office",
        data: {
          key: "view_back_office_tab",
        },
        loadChildren: () =>
          import("./back-office/back-office.module").then(
            (m) => m.BackOfficeComponentModule
          ),
        canActivate: [AuthorizationGuard],
      },
      {
        path: "remote-sensing",
        data: {
          key: "view_remote_sensing_tab",
        },
        loadChildren: () =>
          import("./remote-sensing/remote-sensing.module").then(
            (m) => m.RemoteSensingComponentModule
          ),
        canActivate: [AuthorizationGuard],
      },
      {
        path: "unimart",
        data: {
          key: "view_unimart_tab",
        },
        loadChildren: () =>
          import("./unimart/unimart.module").then(
            (m) => m.UnimartComponentModule
          ),
        canActivate: [AuthorizationGuard],
      },
      {
        path: "unimart/:tab",
        data: {
          key: "view_unimart_tab",
        },
        loadChildren: () =>
          import("./unimart/unimart.module").then(
            (m) => m.UnimartComponentModule
          ),
        canActivate: [AuthorizationGuard],
      },
      {
        path: "unimart-outlets",
        data: {
          key: "view_unimart_tab",
        },
        loadChildren: () =>
          import("./unimart-outlets/unimart-outlets.module").then(
            (m) => m.UnimartOutletsComponentModule
          ),
        canActivate: [AuthorizationGuard],
      },
      {
        path: "unimart-employee-attendance-report",
        data: {
          key: "view_unimart_tab",
        },
        loadChildren: () =>
          import("./unimart-employee-attendance-report/unimart-employee-attendance-report.module").then(
            (m) => m.UnimartEmployeeAttendanceReportComponentModule
          ),
        canActivate: [AuthorizationGuard],
      },
      {
        path: "machine-ble-mapping",
        data: {
          key: "view_machine_tab",
        },
        loadChildren: () =>
          import("./machine-ble-mapping/machine-ble-mapping.module").then(
            (m) => m.MachineBleMappingComponentModule
          ),
        canActivate: [AuthorizationGuard],
      },
      {
        path: "campaign",
        data: {
          key: "ROLE_CAMPAIGN_MANAGEMENT",
        },
        loadChildren: () =>
          import("./campaign-ui/campaign-ui.module").then((m) => m.CampaignUiModule),
        canActivate: [AuthorizationGuard],
      },
      {
        path: "all-banner-details",
        data: {
          key: "view_banners_tab",
        },
        loadChildren: () =>
          import("./banner-ui/banner-ui.module").then((m) => m.BannerUiModule),
        canActivate: [AuthorizationGuard],
      },
      {
        path: "notice-board",
        data: {
          key: "view_content_management_tab",
        },
        loadChildren: () =>
          import("./notice-board/notice-board.module").then(
            (m) => m.NoticeBoardComponentModule
          ),
        canActivate: [AuthorizationGuard],
      },
      {
        path: "announcement",
        data: {
          key: "view_content_management_tab",
        },
        loadChildren: () =>
          import("./announcement/announcement.module").then(
            (m) => m.AnnouncementComponentModule
          ),
        canActivate: [AuthorizationGuard],
      },
      {
        path: "announcement/:tab",
        data: {
          key: "view_content_management_tab",
        },
        loadChildren: () =>
          import("./announcement/announcement.module").then(
            (m) => m.AnnouncementComponentModule
          ),
        canActivate: [AuthorizationGuard],
      },
      {
        path: "machine-village-mapping",
        data: {
          key: "view_machine_tab",
        },
        loadChildren: () =>
          import("./machine-village-mapping/machine-village-mapping.module").then(
            (m) => m.MachineVillageMappingComponentModule
          ),
        canActivate: [AuthorizationGuard],
      },
      {
        path: "spare-parts",
        data: {
          key: "view_machine_tab",
        },
        loadChildren: () =>
          import("./spare-part/spare-part.module").then(
            (m) => m.MachineSparePartComponentModule
          ),
        canActivate: [AuthorizationGuard],
      },
      {
        path: 'state-budget',
        data: {
          key: "VIEW_STATE_BUDGET",
        },
        loadChildren: () =>
          import('./state-parts-budgets/state-parts-budgets.module').then(
            m => m.StatePartsBudgetsModule
          ),
        canActivate: [AuthorizationGuard],
      },
      {
        path: "upload-portal",
        data: {
          key: "view_insurance_details_tab",
        },
        loadChildren: () =>
          import("../pages/upload-portal/upload-portal.module").then((m) => m.UploadPortalModule),
        canActivate: [AuthorizationGuard],
      },
      {
        path: "registration-portal",
        data: {
          key: "view_insurance_details_tab",
        },
        loadChildren: () =>
          import("../pages/registration-portal/registration-portal.module").then((m) => m.RegistrationPortalModule),
        canActivate: [AuthorizationGuard],
      },
      {
        path: "daily-quiz",
        data: {
          key: "view_poll_and_quizzes_tab",
        },
        loadChildren: () =>
          import("../pages/polls-ui/daily-quiz/daily-quiz.module").then(
            (m) => m.DailyQuizModule
          ),
        canActivate: [AuthorizationGuard],
      },
      {
        path: "view-daily-quiz/:id",
        data: {
          key: "view_poll_and_quizzes_tab",
        },
        loadChildren: () =>
          import("../pages/polls-ui/daily-quiz-details/daily-quiz-details.module").then(
            (m) => m.DailyQuizDetailsModule
          ),
        canActivate: [AuthorizationGuard],
      },
      {
        path: "land-record-verification",
        data: {
          key: "ROLE_PICTURE_VERIFICATION",
        },
        loadChildren: () =>
          import("./land-record-verification/land-record-verification.module").then(
            (m) => m.LandRecordVerificationModule
          ),
        canActivate: [AuthorizationGuard],
      },
      {
        path: "product-scan",
        data: {
          key: "view_product_scan_details_tab",
        },
        loadChildren: () =>
          import("./product-scan/product-scan.module").then(
            (m) => m.ProductScanModule
          ),
        canActivate: [AuthorizationGuard],
      },
      {
        path: "user-tabs-mapping",
        data: {
          key: "view_user_authority_mapping_tab",
        },
        loadChildren: () =>
          import("./user-tabs-mapping/user-tabs-mapping.module").then(
            (m) => m.UserTabMappingModule
          ),
        canActivate: [AuthorizationGuard],
      },
      {
        path: "product-scan",
        data: {
          key: "view_product_scan_details_tab",
        },
        loadChildren: () =>
          import("./product-scan/product-scan.module").then(
            (m) => m.ProductScanModule
          ),
        canActivate: [AuthorizationGuard],
      },
      {
        path: "soil-testing",
        data: {
          key: "view_soil_testing",
        },
        loadChildren: () =>
          import("./soil-testing/soil-testing.module").then(
            (m) => m.SoilTestingModule
          ),
        canActivate: [AuthorizationGuard],
      },
      {
        path: "tag-screenshots",
        data: {
          key: "ROLE_PICTURE_VERIFICATION",
        },
        loadChildren: () =>
          import("./tag-screenshots/tag-screenshots.module").then(
            (m) => m.TagScreenshotsModule
          ),
        canActivate: [AuthorizationGuard],
      },
    ],
    canActivate: [MsalAuthGuard],
  },
  {
    path: "**",
    redirectTo: "home",
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }


