import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app.routing';
import { environment } from '../environments/environment';
import { AgmCoreModule } from '@agm/core';
import { ReportsConstants } from "./constants/app.constant"
import { AngularMultiSelectModule } from "angular2-multiselect-dropdown";
import { AngularFireModule } from "@angular/fire/compat";
import { AngularFirePerformanceModule } from "@angular/fire/compat/performance";
import * as $ from "jquery";

// App is our top level component
import { App } from "./app.component";
import { AppState } from "./helpers/app.state";
import { GlobalState } from "./helpers/global.state";
import { NgaModule } from "../app/theme/nga.module";
import { PagesModule } from "./pages/pages.module";
import { AuthenticationHelper } from "./helpers/authentication";
import {
  LoginGuard,
  AuthGuard,
  AllUsersGuard,
  BDOSuperAdminGuard,
  SuperAdminGuard,
  PointCreditGuard,
  SupportUserGuard,
  SaleUserGuard,
  AuthorizationGuard,
} from "./guards/app.guard";
import { UtilityHelper } from "./helpers/utility";
import { GlobalEvents } from "./helpers/global.events";
import { MyDatePickerModule } from "mydatepicker";
import { PagerService } from "./helpers/pager";
import { RestangularModule, Restangular } from "ngx-restangular";
import { States } from "./shared/states";

// API services
import { UserService } from "./app-services/user-service";
import { MachineService } from "./app-services/machine-service";
import { GeographyService } from "./app-services/geography-service";
import { AuthenticationService } from "./app-services/authentication-service";
import { MachineOperatorMappingService } from "./app-services/machine-operator-mapping-service";
import { ReportService } from "./app-services/reports-service";
import { BookingService } from "./app-services/booking-service";
import {
  RemoteSensingService,
  RESTANGULAR_REMOTE_SENSING,
  RestangularRemoteSensingFactory,
} from "./app-services/remote-sensing-service";
import { FarmerService } from "./app-services/farmer-service";
import {
  UnimartService,
  RESTANGULAR_UNIMART,
  RestangularUnimartFactory,
} from "./app-services/unimart-service";
import { ServiceWorkerModule } from "@angular/service-worker";
import { Subscription } from "rxjs";
import { NoticeBoardService } from "./app-services/notice-board-service";
import { NgxSmartModalModule } from "ngx-smart-modal";
import { MsalModule, MsalRedirectComponent } from "@azure/msal-angular";
import { PublicClientApplication } from "@azure/msal-browser";

const isIE =
  window.navigator.userAgent.indexOf("MSIE ") > -1 ||
  window.navigator.userAgent.indexOf("Trident/") > -1;
const MY_MOMENT_DATE_TIME_FORMATS: any = ReportsConstants.MY_MOMENT_DATE_TIME_FORMATS;


export function RestangularConfigFactory(RestangularProvider) {
  RestangularProvider.setBaseUrl(environment.baseUrl);
  let token = localStorage.getItem("isTokenExpire");
  RestangularProvider.addErrorInterceptor(
    (response, subject, responseHandler) => {
      if (response.status === 401 && !token) {
        localStorage.setItem("isTokenExpire", "true");
        window.location.reload();
      }
    }
  );

  // by each request to the server receive a token and update headers with it
  RestangularProvider.addFullRequestInterceptor(
    (data, operation, path, url, headers, params) => {
      let bearerToken = AuthenticationHelper.getToken();
      return {
        headers: Object.assign({}, headers, {
          Authorization: bearerToken ? `Bearer ${bearerToken}` : "",
          "Accept-Language": "en-us",
        }),
      };
    }
  );

  RestangularProvider.addResponseInterceptor(
    (data, operation, what, url, response) => {
      switch (operation) {
        case "post":
        case "put":
        case "remove":
          if (!data) return {};
        default: {
          if (typeof data === "number") {
            return { res: data };
          }
          if (typeof data === "object") {
            return data;
          }
        }
      }
    }
  );
}

// Application wide providers
const APP_PROVIDERS = [
  AppState,
  GlobalState,
  LoginGuard,
  AuthGuard,
  AllUsersGuard,
  BDOSuperAdminGuard,
  SupportUserGuard,
  SuperAdminGuard,
  PointCreditGuard,
  SaleUserGuard,
  AuthenticationHelper,
  PagerService,
  UtilityHelper,
  GlobalEvents,
  States,
  AuthorizationGuard,
];

// Application service providers
const APP_SERVICE_PROVIDERS = [
  UserService,
  MachineService,
  GeographyService,
  AuthenticationService,
  MachineOperatorMappingService,
  ReportService,
  BookingService,
  RemoteSensingService,
  FarmerService,
  UnimartService,
  NoticeBoardService,
  {
    provide: RESTANGULAR_UNIMART,
    useFactory: RestangularUnimartFactory,
    deps: [Restangular],
  },
  {
    provide: RESTANGULAR_REMOTE_SENSING,
    useFactory: RestangularRemoteSensingFactory,
    deps: [Restangular],
  },
  { provide: Subscription, useValue: undefined },
];

import { CallbackComponent } from "./callback.component";


/**
 * `AppModule` is the main entry point into Angular6's bootstraping process
 */
@NgModule({
  bootstrap: [App, MsalRedirectComponent],
  declarations: [App, CallbackComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    RouterModule,
    FormsModule,

    // To Support disable input fields whcih is diprecated in angular 14
    ReactiveFormsModule.withConfig({
      callSetDisabledState: 'whenDisabledForLegacyCode',
    }),
    NgaModule,
    NgbModule,
    PagesModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({ preventDuplicates: true }),
    MyDatePickerModule,
    AngularMultiSelectModule,
    RestangularModule.forRoot([AuthenticationHelper], RestangularConfigFactory),
    AgmCoreModule.forRoot({
      apiKey: environment.mapApiKey,
    }),
    ServiceWorkerModule.register("/ngsw-worker.js", {
      enabled: environment.production,
    }),
    NgxSmartModalModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirePerformanceModule,
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: {
          clientId: '1d0996d5-a7f4-487b-aefd-66f07a2f3e30', // Application (client) ID from the app registration
          authority: 'https://login.microsoftonline.com/8bc97f9a-ea86-472e-8ab7-19db58c4c8af/oauth2/v2.0/authorize', // The Azure cloud instance and the app's sign-in audience (tenant ID, common, organizations, or consumers)
          redirectUri: `${window.location.origin}/afs-admin/callback`, // This is your redirect URI,

        },
        cache: {
          cacheLocation: "localStorage",
          storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
        },
      }),
      null,
      null
    ),
  ],
  providers: [
    // expose our Services and Providers into Angular's dependency injection
    APP_PROVIDERS,
    APP_SERVICE_PROVIDERS,
    //PerformanceMonitoringService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
  constructor() { }
}
