import { Component } from "@angular/core";
import {
  Routes,
  Router,
  RouterEvent,
  NavigationStart,
  NavigationCancel,
  NavigationError,
} from "@angular/router";
import { BaMenuService } from "../theme";
import { PAGES_MENU } from "./pages.menu";
import { BaThemeSpinner } from "../theme/services/baThemeSpinner/baThemeSpinner.service";
import { GlobalEvents } from "../helpers/global.events";
import { AuthenticationHelper } from "../helpers/authentication";
import * as _ from "lodash";

@Component({
  selector: "pages",
  template: `
    <ba-sidebar></ba-sidebar>
    <ba-page-top></ba-page-top>
    <div class="al-main" [ngClass]="{ 'ml-0': events.isFullScreenMap }">
      <div class="al-content">
        <ba-content-top></ba-content-top>
      </div>
      <div>
        <router-outlet></router-outlet>
      </div>
    </div>
    <footer class="al-footer clearfix">
      <div class="al-footer-main clearfix">
        <div class="al-copy">
          &copy;
          <a href="https://web.nurture.farm/afs-admin" translate
            >web.nurture.farm </a
          >{{ currentYear }}
        </div>
      </div>
    </footer>
    <ba-back-top position="200"></ba-back-top>
  `,
})
export class Pages {
  currentYear;

  constructor(
    public events: GlobalEvents,
    private _menuService: BaMenuService,
    private router: Router,
    private _spinner: BaThemeSpinner
  ) {
    router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
    });
  }

  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this._spinner.show();
    }
    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this._spinner.hide();
    }
    if (event instanceof NavigationError) {
      this._spinner.hide();
    }
  }

  createMenu() {
    const dashboard = {
      path: "dashboard",
      data: {
        menu: {
          title: " Dashboard",
          icon: "fa fa-dashboard",
          selected: false,
          expanded: false,
          order: 0,
        },
      },
    };
    let loggedInUser = AuthenticationHelper.getLoggedInUserDetails();
    let assinedAuthorities;
    if (loggedInUser && loggedInUser.authorities) {
      assinedAuthorities = PAGES_MENU.filter(({ key }) =>
        loggedInUser.authorities.some(
          ({ authority }) => key.trim() === authority.trim()
        )
      );
    }
    assinedAuthorities.splice(0, 0, dashboard);
    return assinedAuthorities;
  }

  ngOnInit() {
    let loggedInUser = AuthenticationHelper.getLoggedInUserDetails();
    if (loggedInUser && loggedInUser.authorities) {
      this._menuService.updateMenuByRoutes(<Routes>this.createMenu());
    }
    this.currentYear = new Date().getFullYear();
  }
}
