import { Injectable } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationHelper } from './authentication';

@Injectable({
  providedIn: 'root'
})
export class MsalServiceService {

  constructor(
    private newAuthService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
  ) { }

  loginMSAL() {
    this.newAuthService.loginRedirect().subscribe(res => {
      console.log('login success', res);
    }, err=> {
      console.log('login error', err);
    });
  }

  logout() { // Add log out function here
    this.newAuthService.logoutRedirect({
      postLogoutRedirectUri: `${window.location.origin}/afs-admin/home`
    });
  }

  public isAuthenticated() {
    const accounts = this.newAuthService?.instance?.getAllAccounts();
    return accounts.length > 0;
  }

}
