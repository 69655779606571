import {Injectable} from '@angular/core';
import {Restangular} from 'ngx-restangular';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import { environment } from 'environments/environment';
import { AuthenticationHelper } from 'app/helpers/authentication';

@Injectable()
export class FarmerService {

    baseURL: string = environment.baseUrl;

    getFarmerNDVIByFarmerGUIDRoute: any = 'api/satellite/images/ndvi/farmer';
    getFarmerWeatherResourceRoute: any = 'api/weather/forecast/daily';
    getFarmerSoilMoistureRoute: any = 'api/soil-moistures-by-farmer';
    getFarmerMandiResourceRoute: any = 'api/mandi/crop-prices-by-farmer';
    getFarmerMoneySpentResourceRoute: any = 'api/spentByFarmerFromJune';
    getFarmerFeedbackRoute: any = 'api/farmerFeedback';
    createSaveFarmingExpenseRoute: any = 'api/farmingExpenses/saveAllExpenses';
    getFarmerExpenseRoute: any = 'api/farmerExpenses';
    createFarmingExpenseRoute: any = 'api/farmingExpenses';
    getFarmerExpensesTypeRoute: any = 'api/farmingExpensesMaster';
    getSprayingByFarmerFromDateRoute: any = 'api/sprayingByFarmerFromDate';
    getSprayingByFarmerRoute: any = 'api/sprayingByFarmer';
    getFarmerFarmImagesRoute: any = 'api/farmerFarmImages/getByFarmer';
    uploadFarmImagesRoute: any = 'api/farmerFarmImages';
    getALLFarmerExpenseRoute: any = 'api/getFarmerExpensesForCurrentFarmingYear';
    getSprayingDataRoute: any = 'api/spraying-pattern';
    getHoshpicashDataUrl: any = '/api/farmer-health-insurance/get-info';
    cancelKavachRoute: any = '/api/insurance/cancel-kavach';

    constructor(private restangular: Restangular, private http: HttpClient) {

    }

    /**
     * To create custom header by passing NF-Service-Area-Id
     */
     createHeaderWithServiceAreaId() {
        return (new HttpHeaders({
            Authorization: `Bearer ${AuthenticationHelper.getToken()}`,
            'NF-Service-Area-Id': localStorage.getItem('serviceAreaId')
        }));
    }

    /**
     * To create custom header by passing NF-Service-Area-Id
     */
    createHeaderWithoutServiceAreaId() {
        return (new HttpHeaders({
            Authorization: `Bearer ${AuthenticationHelper.getToken()}`
        }));
    }

    /**
     * api/satellite/images/ndvi/farmer
     */
    getFarmerNDVIByFarmerGUID(farmerGuid?) {
        return this.restangular.one(this.getFarmerNDVIByFarmerGUIDRoute, farmerGuid);
    }

    /**
     * api/weather/forecast/daily
     */
    getWeatherResourceByFarmerGuid() {
        return this.restangular.one(this.getFarmerWeatherResourceRoute);
    }

    /**
     * api/soil-moistures-by-farmer
     */
    getSoilMoistureByFarmerGuid() {
        return this.restangular.one(this.getFarmerSoilMoistureRoute);
    }

    /**
     * api/mandi/crop-prices-by-farmer
     */
    getMandiResourceByFarmerGuid() {
        return this.restangular.one(this.getFarmerMandiResourceRoute);
    }

    /**
     * api/mandi/crop-prices-by-farmer
     */
    getFarmerMoneySpentResourceByFarmerGuid() {
        return this.restangular.one(this.getFarmerMoneySpentResourceRoute);
    }

    /**
     * api/farmerFeedback
     */
    getFarmerFeedbackResource() {
        return this.restangular.all(this.getFarmerFeedbackRoute);
    }

    /**
     * api/farmingExpenses
     */
    createFarmingExpense() {
        return this.restangular.all(this.createSaveFarmingExpenseRoute);

    }

    /**
     * api/farmingExpenses
     */
    getFarmingExpense(guid) {
        return this.restangular.one(this.getFarmerExpenseRoute, guid);
    }

    /**
     * api/farmingExpensesMaster
     */
    getAllFarmerExpenseTypeList() {
        return this.restangular.all(this.getFarmerExpensesTypeRoute);

    }

    /**
     * api/sprayingByFarmerFromDate
     */
    getAllSprayingByFarmerFromDate() {
        return this.restangular.one(this.getSprayingByFarmerFromDateRoute);

    }

    /**
     * api/getAllSprayingByFarmer
     */
    getAllSprayingByFarmer() {
        return this.restangular.one(this.getSprayingByFarmerRoute);

    }

    /**
     * api/farmerFarmImages/getByFarmer
     */
    getAllFarmerFarmImages(farmerGUID) {
        return this.restangular.one(this.getFarmerFarmImagesRoute, farmerGUID);

    }

    /**
     * api/farmerFarmImages
     */
    uploadALLFarmImages() {
        return this.restangular.all(this.uploadFarmImagesRoute);
    }

    /**
     * api/getFarmerExpensesForCurrentFarmingYear
     */
    getALLFarmerExpenseForCurrentFarmingYear (farmerGuid) {
        return this.restangular.one(this.getALLFarmerExpenseRoute, farmerGuid);
    }

    /**
     * api/farmingExpenses
     */
    createSingleFarmingExpense () {
        return this.restangular.all(this.createFarmingExpenseRoute);
    }

    /**
     * api/farmerExpenses/farmerExpenseId
     */
    deleteSingleExpense (expenseId) {
        return this.restangular.one(this.getFarmerExpenseRoute, expenseId);
    }

    getAllSprayingData(id) {
        return this.restangular.one(this.getSprayingDataRoute,id);
    }

    getHoshpicashDetails(id, type){
        const url = `${this.baseURL}api/farmer-health-insurance/get-info/${id}?insuranceTypeId=${type}`;
        return this.http.get(url,{
            headers: localStorage.getItem('serviceAreaId')
                ? this.createHeaderWithServiceAreaId() : this.createHeaderWithoutServiceAreaId()
        })
    }

    getWeatherKavachDetailsByFarmerId(farmerId){
        const url = `${this.baseURL}api/insurance/schemes/actor/${farmerId}`;
        return this.http.get(url,{
            headers: localStorage.getItem('serviceAreaId')
                ? this.createHeaderWithServiceAreaId() : this.createHeaderWithoutServiceAreaId()
        })
    }

    cancelKavachInsurance(eventId, farmerId) {
        return this.restangular.one(`${this.cancelKavachRoute}/${eventId}/${farmerId}`);
    }

    getSchemeDataBySchemeTypeId(code, schemTypeId) {
        const url = `${this.baseURL}api/insurance/schemes/geo/${code}/scheme-type/${schemTypeId}`;
        return this.http.get(url, {
            headers: localStorage.getItem('serviceAreaId')
                ? this.createHeaderWithServiceAreaId() : this.createHeaderWithoutServiceAreaId()
        })
    }

    getInsuranceSchemesGeotype() {
        const url = `${this.baseURL}api/insurance/schemes/geo-type`;
        return this.http.get(url, {
            headers: localStorage.getItem('serviceAreaId')
                ? this.createHeaderWithServiceAreaId() : this.createHeaderWithoutServiceAreaId()
        })
    }

    getSchemeDataListByGeoTypedAndKavach(code, kavach) {
        const url = `${this.baseURL}api/insurance/schemes/geo/${code}/category/${kavach}`;
        return this.http.get(url, {
            headers: localStorage.getItem('serviceAreaId')
                ? this.createHeaderWithServiceAreaId() : this.createHeaderWithoutServiceAreaId()
        })
    }

    createHospicashApiRequest(payload) {
        const url = `${this.baseURL}api/farmer-health-insurance`;
        return this.http.post(url, payload, {
            headers: localStorage.getItem('serviceAreaId')
                ? this.createHeaderWithServiceAreaId() : this.createHeaderWithoutServiceAreaId()
        });
    }

    getWalletBalanceByFarmerNumberApiRequest(number) {
        const url = `${this.baseURL}api/insurance/farmer-wallet-balance/${number}`;
        return this.http.get(url, {
            headers: localStorage.getItem('serviceAreaId')
                ? this.createHeaderWithServiceAreaId() : this.createHeaderWithoutServiceAreaId()
        })
    }

    getFarmerLoactionByFarmerId(farmerId) {
        const url = `${this.baseURL}api/insurance/location/actor/${farmerId}`;
        return this.http.get(url,{
            headers: localStorage.getItem('serviceAreaId')
                ? this.createHeaderWithServiceAreaId() : this.createHeaderWithoutServiceAreaId()
        })
    }

    createWeatherKavachApiRequest(payload) {
        const url = `${this.baseURL}api/insurance/kavach`;
        return this.http.post(url, payload, {
            headers: localStorage.getItem('serviceAreaId')
                ? this.createHeaderWithServiceAreaId() : this.createHeaderWithoutServiceAreaId()
        });
    }

    getNomneeRelationshipListData() {
        const url = `${this.baseURL}api/insurance/get-drop-down-master/NOMINEE_RELATIONSHIP`;
        return this.http.get(url, {
            headers: localStorage.getItem('serviceAreaId')
                ? this.createHeaderWithServiceAreaId() : this.createHeaderWithoutServiceAreaId()
        })
    }

    getGenderListData() {
        const url = `${this.baseURL}api/insurance/get-drop-down-master/GENDER`;
        return this.http.get(url, {
            headers: localStorage.getItem('serviceAreaId')
                ? this.createHeaderWithServiceAreaId() : this.createHeaderWithoutServiceAreaId()
        })
    }

    getQodeNextDetailsOfProduct(code) {
        const url = `${this.baseURL}api/qodenext-by-barcode/${code}`;
        return this.http.get(url, {
            headers: localStorage.getItem('serviceAreaId')
                ? this.createHeaderWithServiceAreaId() : this.createHeaderWithoutServiceAreaId()
        })
    }

    getFarmerAppScanDetailsOfProduct(code) {
        const url = `${this.baseURL}api/farmer-product-scan-details?barcodeUuid=${code}`;
        return this.http.get(url, {
            headers: localStorage.getItem('serviceAreaId')
                ? this.createHeaderWithServiceAreaId() : this.createHeaderWithoutServiceAreaId()
        })
    }

    getOperatorAppScanDetailsofProduct(code) {
        const url = `${this.baseURL}api/operator-scan-by-barcode/${code}`;
        return this.http.get(url, {
            headers: localStorage.getItem('serviceAreaId')
                ? this.createHeaderWithServiceAreaId() : this.createHeaderWithoutServiceAreaId()
        })
    }

    /**
     * api/fsoil-testing/upload-file
     */
    getPreSighnedUrl(path, type, bookingId) {
        const url = `api/soil-testing/upload-file?path=${path}&file-extension=${type}&bookingId=${bookingId}`;
        return this.restangular.all(url);
    }

    /**
     * api/soil-testing
     */

    saveSoilTestingData() {
        const url = 'api/soil-testing';
        return this.restangular.all(url);
    }

    createHeaderForSoilQRCodeVerify() {
        return (new HttpHeaders({
            Authorization: `Bearer ${AuthenticationHelper.getToken()}`,
            'type' : 'soil_testing_scan'
        }));
    }

    /**
     * api/validate-barcode/{barcode}
     */

    VerifySoilTestingQRCode(code) {
        const url = `${this.baseURL}api/validate-barcode/soil_testing_bag_${code}`;
        return this.http.get(url, {
            headers: this.createHeaderForSoilQRCodeVerify()
        })
    }

    /**
     * api for upload file on preSigned url
     */

    uploadOnPreSignedURL(url, data, fileType) {
        return this.http.put(url, data, {
            headers : new HttpHeaders({
                "Content-Type" : fileType,
            })
        });
    }

    /**
     * api for get all available subciptions list
     * afs/api/subscriptions/plans`
    */
    getCareSubcriptionTypesList() {
        const url = `${this.baseURL}api/subscriptions/plans`;
        return this.http.get(url,{
            headers: localStorage.getItem('serviceAreaId')
                ? this.createHeaderWithServiceAreaId() : this.createHeaderWithoutServiceAreaId()
        })
    }

    /**
     * api for send subsciption link to farmer
     * afs/api/subscriptions/plans/activate`
    */
    onSendSubcriptionPaymnetLink(paylod) {
        const url = `${this.baseURL}api/subscriptions/plans/activate`;
        return this.http.post(url, paylod ,{
            headers: localStorage.getItem('serviceAreaId')
                ? this.createHeaderWithServiceAreaId() : this.createHeaderWithoutServiceAreaId()
        })
    }

    /**
     * afs/api/subscriptions/plans/purchased
     * @param paylod 
     * @returns 
     */
    getCareSubcriptionListOfFarmer(farmerId, pageNumber, size) {
        const url = `${this.baseURL}api/subscriptions/plans/purchased/${farmerId}?page=${pageNumber}&size=${size}`;
        return this.http.get(url,{
            headers: localStorage.getItem('serviceAreaId')
                ? this.createHeaderWithServiceAreaId() : this.createHeaderWithoutServiceAreaId()
        })
    }

    getProductScanImages(payload) {
        const url = `${this.baseURL}api/screen-shots`;
        return this.http.post(url, payload, {
            headers: localStorage.getItem('serviceAreaId')
                ? this.createHeaderWithServiceAreaId() : this.createHeaderWithoutServiceAreaId()
        })
    }

    setImageTags(id, payload) {
        const url = `${this.baseURL}api/screen-shots/tags/${id}`;
        return this.http.patch(url, payload, {
            headers: localStorage.getItem('serviceAreaId')
                ? this.createHeaderWithServiceAreaId() : this.createHeaderWithoutServiceAreaId()
        })
    }

    getTagsList() {
        const url = `${this.baseURL}api/screen-shots/tags`;
        return this.http.get(url, {
            headers: localStorage.getItem('serviceAreaId')
                ? this.createHeaderWithServiceAreaId() : this.createHeaderWithoutServiceAreaId()
        })
    }

    getScanStatusList() {
        const url = `${this.baseURL}api/screen-shots/statusList`;
        return this.http.get(url, {
            headers: localStorage.getItem('serviceAreaId')
                ? this.createHeaderWithServiceAreaId() : this.createHeaderWithoutServiceAreaId()
        })
    }

    // createHeader() {
    //     return (new HttpHeaders({
    //         Authorization: `Bearer ${AuthenticationHelper.getToken()}`,
    //         'x-actor-type': 'ABC',
    //         'x-actor-id': '123'
    //     }));
    // }
}
